/* ContactForm.css */

.contact-form-container {
  position: relative;
}

.success-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it appears above other elements */
}

.success-popup-content {
  background-color: #fff;
  padding: 40px;
  border-radius: 8px;
  text-align: center;
}

.success-popup-content h2 {
  margin-top: 20px;
  font-size: 24px;
  color: #28a745; /* Bootstrap success color */
}

.success-popup-content p {
  margin-top: 10px;
  font-size: 16px;
}

.success-popup-content .btn {
  margin-top: 20px;
}
