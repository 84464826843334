html {
  scroll-behavior: smooth;
  scroll-snap-type: y proximity;
}

@font-face {
  font-family: 'wttw_title';
  src: url('./fonts/A_Love_of_Thunder.ttf');
}

@font-face {
  font-family: 'wttw_text';
  src: url('./fonts/AntennaCond-Regular.otf');
}
@font-face {
  font-family: 'wttw_text';
  src: url('./fonts/AntennaCond-Bold.otf');
  font-weight: 500;
}

.App {
  text-align: center;
}

.loading-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}

.circle-wrapper {
  position: relative;
  width: 150px;
  height: 150px;
}

.loading-logo {
  width: 80%;
  height: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.loading-circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 4px solid transparent;
  border-top: 4px solid #007bff; /* Adjust color as needed */
  border-radius: 50%;
  animation: rotate 2s linear infinite;
}

.loading-text {
  margin-top: 20px;
  font-size: 18px;
  color: #333;
  font-family: Arial, sans-serif;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.parallax-container {
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: hidden;
}
.first-content {
  width: 100%;
}
.fixed {
  position: sticky;
  overflow-x: hidden;
}

.relative {
  position: relative;
  overflow-x: hidden;
}

.sticky {
  position: sticky;
  top: 0;
  align-self: start;
  overflow-x: hidden;
}
.parallax-banner {
  height: 100vh;
  transition: opacity 0.5s;
}

.background-layer {
  position: absolute;
  width: 100vw;
  top: 0;
  left: 0;
  background-image: url('./img/background.jpg');
  background-size: cover;
  background-position: center;
}

.foreground-layer {
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0;
  background-image: url('./img/foreground.png');
  background-repeat: no-repeat;
  background-position: center;
}


.arrow-container {
  position: absolute;
  bottom: 100px; 
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}

.circle-animation {
  position: relative;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-animation::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #fff;
  border-radius: 50%;
  animation: pulse 2s infinite;
  box-sizing: border-box;
}

.circle-animation i {
  font-size: 32px;
  color: #fff;
  z-index: 1;
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
    opacity: 1;
  }
  70% {
    transform: scale(1.5);
    opacity: 0;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

.scrollable-area {
  height: 2000vh; /* Adjust this value to give enough scrollable area */
}

.second-content {
  padding: 50px 20px;
  width: 100%;
}

.first-content, .third-content, .fourth-content, .fifth-content{
  scroll-snap-align: start;
  transition: opacity 1s ease-in-out;
  opacity: 0;
}

.programme-content {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.programme-image {
  height: auto;
  position: relative;
}
.fadeIn {
  animation: fadeIn 1s;
  opacity: 1;
}

.fadeOut {
  animation: fadeOut 1s;
  opacity: 0;
}

@keyframes fadeIn {
  0% {
      opacity: 0;
      transform: scale(0.8);
  }
  100% {
      opacity: 1;
      transform: scale(1);
  }
}


@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}
.logo-container {
  text-align: center;
  position: relative;
}

.logobackground-layer {
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  left: 0vh;
  background-size: cover;
}
.title {
  font-weight: normal;
  font-family: wttw_title;
}
.title-values {
  font-weight: normal;
  font-family: wttw_title;
  position: relative;
}
.title-content {
  letter-spacing: 1px;
  margin-top:20px;
}
.aboutus {
  text-align: center;
}

.programme-title {
  position: relative;
  font-weight: bold;
  z-index: 1; /* Ensure the title is above the pseudo-elements */
  margin: 10px 0;
  padding: 10px 20px;
  text-align: center;
  color: white; /* Ensure the text is visible over the backgrounds */
  overflow: hidden;
  padding: 40px 100px 30px 50px;
  font-weight: 500;
  color: #25282c;
}
  
.programme-title::before {
  content: '';
  position: absolute;
  transform: translate(-46%, -66%);
  background-size: cover;
  z-index: -1; /* Place behind the title */
  opacity: 0.8;
  border-radius: 50%; /* Makes it an oval */
  animation: waveMove 20s infinite ease-in-out;
}
.programme-titletext {
  position: relative;
  text-decoration: none;
  color: #000
}

.programme-text-up {
  font-family: wttw_text;
  font-weight: 500;
}

@keyframes waveMove {
  0%, 100% {
    border-top-left-radius: 100% 90%;
    border-top-right-radius: 50% 90%;
    border-bottom-left-radius: 90% 90%;
    border-bottom-right-radius: 50% 50%;
  }
  10% {
    border-top-left-radius: 60% 40%;
    border-top-right-radius: 40% 60%;
    border-bottom-left-radius: 50% 90%;
    border-bottom-right-radius: 90% 50%;
    width: 19vh;
    height: 8vh;
  }
  20% {
    border-top-left-radius: 40% 60%;
    border-top-right-radius: 60% 40%;
    border-bottom-left-radius: 55% 45%;
    border-bottom-right-radius: 45% 55%;
    width: 20vh;
    height: 12vh;
  }
  30% {
    border-top-left-radius: 65% 35%;
    border-top-right-radius: 35% 65%;
    border-bottom-left-radius: 60% 40%;
    border-bottom-right-radius: 40% 60%;
  }
  40% {
    border-top-left-radius: 35% 65%;
    border-top-right-radius: 65% 35%;
    border-bottom-left-radius: 50% 50%;
    border-bottom-right-radius: 50% 50%;
    width: 22vh;
    height: 10vh;
  }
  50% {
    border-top-left-radius: 50% 50%;
    border-top-right-radius: 50% 90%;
    border-bottom-left-radius: 60% 90%;
    border-bottom-right-radius: 40% 60%;
  }
  60% {
    border-top-left-radius: 45% 55%;
    border-top-right-radius: 55% 45%;
    border-bottom-left-radius: 40% 60%;
    border-bottom-right-radius: 60% 40%;
  }
  70% {
    border-top-left-radius: 55% 45%;
    border-top-right-radius: 45% 55%;
    border-bottom-left-radius: 50% 50%;
    border-bottom-right-radius: 50% 50%;
    width: 19vh;
    height: 10vh;
  }
  80% {
    border-top-left-radius: 50% 50%;
    border-top-right-radius: 50% 50%;
    border-bottom-left-radius: 45% 55%;
    border-bottom-right-radius: 55% 45%;
  }
  90% {
    border-top-left-radius: 60% 40%;
    border-top-right-radius: 40% 60%;
    border-bottom-left-radius: 55% 45%;
    border-bottom-right-radius: 45% 55%;
  }
}
/* Specific bubbly backgrounds for each program */
.programme-title-0::before {
  background: linear-gradient(135deg, #e9c880, #d4af5f); /* Example color 1 */
}

.programme-title-1::before {
  background: linear-gradient(135deg, #eb8676, #fc4528); /* Example color 2 */
}

.programme-title-2::before {
  background: linear-gradient(135deg, #bdadca, #7a11d8e7); /* Example color 3 */
}

.programme-title-3::before {
  background: linear-gradient(135deg, #72c480, #38f9d7); /* Example color 4 */
}

.programme-title-4::before {
  background: linear-gradient(135deg, #ffae7a, #dac1b1); /* Example color 5 */
}

.programme-title-5::before {
  background: linear-gradient(135deg, #b9d2d2, #7fe3e3); /* Example color 6 */
}



.programmes {
  position: relative;
  height: 100px;
}

.programme-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  transition: opacity 0.5s, transform 0.5s;
  transform: translateX(-100%);
}

.programme-item.visible {
  opacity: 1;
  transform: translateX(0);
}
.relative {
  position: relative;
}

.fixed {
  position: fixed;
}

.bridge-image {
  width: 100%;
  height: auto;
}

.third-content {
  background: white; /* Ensure contrast with bridge image */
  text-align: center;
  display: initial;
}

.worldwide {
  text-align: center;
}

.project-filter {
position: relative;
}

.progress {
  width: 100%;
  height: 20px;
  background-color: #d6eaff; /* Light blue background */
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  background-image: linear-gradient(
    45deg,
    #d6eaff 25%,
    #e6f2ff 25%,
    #e6f2ff 50%,
    #d6eaff 50%,
    #d6eaff 75%,
    #e6f2ff 75%,
    #e6f2ff 100%
  );
  background-size: 40px 40px; /* Adjusted to ensure seamless stripes */
  animation: moveStripes 2s linear infinite; /* Adjusted duration for smooth movement */
}

.progress-bar {
  height: 100%;
  background-color: #007bff; /* Blue color */
  border-radius: 10px;
}

@keyframes moveStripes {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 40px 0px; /* Move by exactly one full repeat of the pattern */
  }
}



.donation-container {
  position: relative;
  display: inline-block;
  margin-top: 0%;
  height: 0px;
  transform: translateY(50px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.packagetabs-container {
  display: flex;
  justify-content: space-around;
  width:100%;
  position: relative;
}

.packagetab {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  padding: 15px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(50px);
  transition: opacity 0.5s ease, transform 0.5s ease;
  overflow: hidden;
}

.packagetab.visible .packagetab-details {
  opacity: 1;
  transform: translateY(0);
}

.packagetab.collapsed {
  transform: translateY(0px);
  transition: transform 1.5s ease;
}

.packagetab-image {
  border-radius: 10px;
}

.packagetab-text {
  border: none;
  background-color: transparent;
  resize: none;
  text-align: center;
  color: #333;
  outline: none;
}

.package-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.package-price {
  font-weight: bold;
}


.packagetab-details {
  list-style-type: none;
  text-align: left;
}

.packagetab-details li::before {
  content: '•';
  color: #007bff;
  display: inline-block;
}

.price-tab {
  font-weight: bold;
  color: #034281;
}

.packagetabs-container.fadeOut,
.donation-container.fadeOut,
.heart.fadeOut {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.piggy-bank {
  display: block;
  z-index:99;
  position:relative;
}

.heart {
  position: absolute;
  background-size: cover;
  background-image: url('./img/heart.png');
  transform: translateX(-50%);
  transition: top 0.5s ease; 
  z-index:0;
}

.donate-button {
  cursor: pointer;
  color: white;
  background-color: #f04;
  border: none;
  border-radius: 5px;
}

.donate-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.donate-popup-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  position: relative;
}

.close-button {
  position: absolute;
  background: none;
  border: none;
  cursor: pointer;
}

.donation-form label {
  display: block;
  font-weight: bold;
}

.donation-form input,
.donation-form textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.contact-form {
  width:100%
}

/* Responsive design adjustments */
@media (min-width: 1922px) {
  .foreground-layer {
    background-size: 40vh;
    top:4vh;
  }
  
  .background-layer {
    height: 115vh;
  }
  
  .logo-container {
    margin-top: -3vh;
    height: 30vh;
  }

  .logobackground-layer {
    width: 110vw;
    height: 55vh;
    top: -5vh;
    background-position-y: 20%;
  }
  
  .logo {
    width: 35vh;
    margin-right: 3vh;
  }
  
  .title-values {
    font-size: 1.5rem;
    position: relative;
    top: 20vh;
  }
  .title-content {
    margin-top:15vh;
    width: 45%;
    translate: 63%;
    line-height: 40px;
    font-size: 20px;
  }
  .moreabout {
  margin-top: 10vh;
  }
  .aboutus {
    margin-top:12vh;
  }
  .programmes {
    height: 100px;
  }
  
  .programmes-title {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .programme-title {
    font-size: 1.5rem;
    left: -1vh;
    margin-bottom: 35px;
  }
    
  .programme-title::before {
    top: 55%;
    left: 33%;
    width: 23vh;
    height: 10vh;
  }
  .programme-titletext {
    margin-left: -4vh;
    top: 7vh;
    font-size: 1.2em;
  }
  .programme-item {
    font-size: 1.2rem;
    line-height: 1.2rem;
    width: 105%;
    margin-top: -5vh;
  }
  .programme-content {
    margin-bottom: 5vh;
    margin-top: -7vh;
  }
  .programme-image {
    width: 23vh; 
    margin-bottom: 10px;
    left: 2vh;
  }
  .programme-text {
    margin-bottom: 3vh;
    letter-spacing: 0.04em;
    margin-left: -8vh;
    font-size: 20px;
  }
  .donatenow {
    margin-top: 30vh;
  }
  .bridge-image {
    width: 100%;
    margin-top: 10%;
    height:400px;
  }
  
  .second-content {
    padding-top: 20vh;
  }
  
  .worldwide {
    margin-top: 50px;
  }
  .projects-title {
    margin-top: 5%;
    margin-bottom: 5%
  }
  .world-map {
    width: 120%;
    max-width: 700px;
    margin-left: -4vh;
    margin-bottom:1vh;
  }
  
  .project-filter {
    width: 50%;
    translate: 50%;
  }
  .milestone-title {
    margin-top: 2% !important;
  }
  .progress-bars {
  width: 50%;
  translate: 50%;
  margin-top:3% !important;
  }
  .progress-bar-container {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .packagetab {
    width: 40%;
    translate: 75%;
    padding-top:35px !important;
  }
  .packagetabs-container {
    margin-top: 5%;
    gap:5px;
  }
  .packagetab.visible {
    margin-top:-2% !important;
  }
  .packagetab.visible .packagetab-details {
    max-height: 500px;
  }
  
  .packagetab.collapsed {
    height: 50px;
  }
  
  .packagetab-image {
    margin-bottom: 10px;
    margin-left: 0%;
    height: 360px;
    width: 83%;
  }
  
  .packagetab-text {
    width: 120%;
    font-size: 14px;
    margin-bottom: 10px;
    margin-left: -15%;
  }
  
  .package-footer {
    margin-top: 10px;
    padding-left:10%;
    padding-right:10%;
  }
  
  .package-price {
    font-size: 1.2em;
  }
  
  
  .packagetab-details {
    padding: 0;
  }
  
  .packagetab-details li {
    margin-bottom: 5px;
    font-size: 14px;
    padding-left:10%;
  }
  
  .packagetab-details li::before {
    width: 1em;
    margin-left: -1em;
  }
  
  .price-tab {
    font-size: 18px;
  }
  
  .donation-container:hover .heart {
    top: 21vh;
  }
  
  .donate-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  
  .donate-popup-content {
    width: 90%;
    max-width: 500px;
  }
  
  .close-button {
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
  }
  
  .donation-form .form-group {
    margin-bottom: 15px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  .contact-form-container {
    width: 40%;
    translate: 80%;
    }
}
@media (min-width: 1851px) and (max-width: 1921px) {
  .foreground-layer {
    background-size: 40vh;
    top:4vh;
  }
  
  .background-layer {
    height: 115vh;
  }
  
  .logo-container {
    margin-top: -3vh;
    height: 30vh;
  }

  .logobackground-layer {
    width: 110vw;
    height: 55vh;
    top: -5vh;
    background-position-y: 20%;
  }
  
  .logo {
    width: 35vh;
    margin-right: 3vh;
  }
  
  .title-values {
    font-size: 1.5rem;
    position: relative;
    top: 20vh;
  }
  .title-content {
    margin-top:15vh;
    width: 45%;
    translate: 63%;
    line-height: 40px;
    font-size: 20px;
  }
  .moreabout {
  margin-top: 10vh;
  }
  .aboutus {
    margin-top:12vh;
  }
  .programmes {
    height: 100px;
  }
  
  .programmes-title {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .programme-title {
    font-size: 1.5rem;
    left: -1vh;
    margin-bottom: 35px;
  }
    
  .programme-title::before {
    top: 55%;
    left: 33%;
    width: 23vh;
    height: 10vh;
  }
  .programme-titletext {
    margin-left: -4vh;
    top: 7vh;
    font-size: 1.2em;
  }
  .programme-item {
    font-size: 1.2rem;
    line-height: 1.2rem;
    width: 105%;
    margin-top: -5vh;
  }
  .programme-content {
    margin-bottom: 5vh;
    margin-top: -7vh;
  }
  .programme-image {
    width: 23vh; 
    margin-bottom: 10px;
    left: 2vh;
  }
  .programme-text {
    margin-bottom: 3vh;
    letter-spacing: 0.04em;
    margin-left: -8vh;
    font-size: 20px;
  }
  .donatenow {
    margin-top: 30vh;
  }
  .bridge-image {
    width: 100%;
    margin-top: 10%;
    height:400px;
  }
  
  .second-content {
    padding-top: 20vh;
  }
  
  .worldwide {
    margin-top: 50px;
  }
  .projects-title {
    margin-top: 5%;
    margin-bottom: 5%
  }
  .world-map {
    width: 120%;
    max-width: 700px;
    margin-left: -4vh;
    margin-bottom:1vh;
  }
  
  .project-filter {
    width: 50%;
    translate: 50%;
  }
  .milestone-title {
    margin-top: 2% !important;
  }
  .progress-bars {
  width: 50%;
  translate: 50%;
  margin-top:3% !important;
  }
  .progress-bar-container {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .packagetab {
    width: 40%;
    translate: 75%;
    padding-top:35px !important;
  }
  .packagetabs-container {
    margin-top: 5%;
    gap:5px;
  }
  .packagetab.visible {
    margin-top:-2% !important;
  }
  .packagetab.visible .packagetab-details {
    max-height: 500px;
  }
  
  .packagetab.collapsed {
    height: 50px;
  }
  
  .packagetab-image {
    margin-bottom: 10px;
    margin-left: 0%;
    height: 360px;
    width: 83%;
  }
  
  .packagetab-text {
    width: 120%;
    font-size: 14px;
    margin-bottom: 10px;
    margin-left: -15%;
  }
  
  .package-footer {
    margin-top: 10px;
    padding-left:10%;
    padding-right:10%;
  }
  
  .package-price {
    font-size: 1.2em;
  }
  
  
  .packagetab-details {
    padding: 0;
  }
  
  .packagetab-details li {
    margin-bottom: 5px;
    font-size: 14px;
    padding-left:10%;
  }
  
  .packagetab-details li::before {
    width: 1em;
    margin-left: -1em;
  }
  
  .price-tab {
    font-size: 18px;
  }
  
  .donation-container:hover .heart {
    top: 21vh;
  }
  
  .donate-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  
  .donate-popup-content {
    width: 90%;
    max-width: 500px;
  }
  
  .close-button {
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
  }
  
  .donation-form .form-group {
    margin-bottom: 15px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  .contact-form-container {
    width: 40%;
    translate: 80%;
    }
}
@media (min-width: 1770px) and (max-width: 1850px) {
  .foreground-layer {
    background-size: 40vh;
    top:4vh;
  }
  
  .background-layer {
    height: 115vh;
  }
  
  .logo-container {
    margin-top: -3vh;
    height: 30vh;
  }
  
  .logobackground-layer {
    width: 110vw;
    height: 55vh;
    top: -5vh;
    background-position-y: 20%;
  }
  
  .logo {
    width: 35vh;
    margin-right: 3vh;
  }
  
  .title-values {
    font-size: 1.5rem;
    position: relative;
    top: 20vh;
  }
  .title-content {
    margin-top:15vh;
    width: 48%;
    translate: 55%;
    line-height: 40px;
    font-size: 20px;
  }
  .moreabout {
  margin-top: 10vh;
  }
  .aboutus {
    margin-top:12vh;
  }
  .programmes {
    height: 100px;
  }
  
  .programmes-title {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .programme-title {
    font-size: 1.5rem;
    left: -1vh;
    margin-bottom: 35px;
  }
    
  .programme-title::before {
    top: 55%;
    left: 33%;
    width: 23vh;
    height: 10vh;
  }
  .programme-titletext {
    margin-left: -4vh;
    top: 7vh;
    font-size: 1.2em;
  }
  .programme-item {
    font-size: 1.2rem;
    line-height: 1.2rem;
    width: 105%;
    margin-top: -5vh;
  }
  .programme-content {
    margin-bottom: 5vh;
    margin-top: -7vh;
  }
  .programme-image {
    width: 23vh; 
    margin-bottom: 10px;
    left: 2vh;
  }
  .programme-text {
    margin-bottom: 3vh;
    letter-spacing: 0.04em;
    margin-left: -8vh;
    font-size: 20px;
  }
  .donatenow {
    margin-top: 30vh;
  }
  .bridge-image {
    width: 100%;
    margin-top: 10%;
    height:400px;
  }
  
  .second-content {
    padding-top: 20vh;
  }
  
  .worldwide {
    margin-top: 50px;
  }
  .projects-title {
      margin-top: 5%;
      margin-bottom: 5%
    }
  .world-map {
    width: 120%;
    max-width: 700px;
    margin-left: -4vh;
    margin-bottom:1vh;
  }
  
  .project-filter {
    width: 50%;
    translate: 50%;
  } 
  .milestone-title {
      margin-top: 2% !important;
    }    
  .progress-bars {
    width: 50%;
    translate: 50%;
    margin-top:3% !important;
    }
  .progress-bar-container {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .packagetab {
    width: 40%;
    translate: 75%;
    padding-top:35px !important;
    }
  .packagetabs-container {
    margin-top: 5%;
    gap:5px;
  }
  .packagetab.visible {
      margin-top:-2% !important;
    }
  .packagetab.visible .packagetab-details {
    max-height: 500px;
  }
  
  .packagetab.collapsed {
    height: 50px;
  }
  
  .packagetab-image {
    margin-bottom: 10px;
    margin-left: 0%;
    height: 360px;
    width: 83%;
  }
  
  .packagetab-text {
    width: 120%;
    font-size: 14px;
    margin-bottom: 10px;
    margin-left: -15%;
  }
  
  .package-footer {
    margin-top: 10px;
    padding-left:10%;
    padding-right:10%;
  }
  
  .package-price {
    font-size: 1.2em;
  }
  
  
  .packagetab-details {
    padding: 0;
  }
  
  .packagetab-details li {
    margin-bottom: 5px;
    font-size: 14px;
    padding-left:10%;
  }
  
  .packagetab-details li::before {
    width: 1em;
    margin-left: -1em;
  }
  
  .price-tab {
    font-size: 18px;
  }
  
  .donation-container:hover .heart {
    top: 21vh;
  }
  
  .donate-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  
  .donate-popup-content {
    width: 90%;
    max-width: 500px;
  }
  
  .close-button {
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
  }
  
  .donation-form .form-group {
    margin-bottom: 15px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  .contact-form-container {
      width: 40%;
      translate: 80%;
      }
}
@media (min-width: 1701px) and (max-width: 1770px) {
  .foreground-layer {
    background-size: 40vh;
    top:4vh;
  }
  
  .background-layer {
    height: 115vh;
  }
  
  .logo-container {
    margin-top: -3vh;
    height: 30vh;
  }
  
  .logobackground-layer {
    width: 110vw;
    height: 55vh;
    top: -5vh;
    background-position-y: 20%;
    }
  
  .logo {
    width: 35vh;
    margin-right: 3vh;
  }
  
  .title-values {
    font-size: 1.5rem;
    position: relative;
    top: 20vh;
  }
  .title-content {
    margin-top:15vh;
    width: 50%;
    translate: 52%;
    line-height: 40px;
    font-size: 20px;
  }
  .moreabout {
    margin-top: 10vh;
  }
  .aboutus {
    margin-top:12vh;
  }
  .programmes {
    height: 100px;
  }
  
  .programmes-title {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .programme-title {
    font-size: 1.5rem;
    left: -1vh;
    margin-bottom: 35px;
  }
    
  .programme-title::before {
    top: 55%;
    left: 33%;
    width: 23vh;
    height: 10vh;
  }
  .programme-titletext {
    margin-left: -4vh;
    top: 7vh;
    font-size: 1.2em;
  }
  .programme-item {
    font-size: 1.2rem;
    line-height: 1.2rem;
    width: 105%;
    margin-top: -5vh;
  }
  .programme-content {
    margin-bottom: 5vh;
    margin-top: -7vh;
  }
  .programme-image {
    width: 23vh; 
    margin-bottom: 10px;
    left: 2vh;
  }
  .programme-text {
    margin-bottom: 3vh;
    letter-spacing: 0.04em;
    margin-left: -8vh;
    font-size: 20px;
  }
  .donatenow {
    margin-top: 30vh;
  }
  .bridge-image {
    width: 100%;
    margin-top: 10%;
    height:400px;
  }
  
  .second-content {
    padding-top: 20vh;
  }
  
  .worldwide {
    margin-top: 50px;
  }
  .projects-title {
    margin-top: 5%;
    margin-bottom: 5%
  }
  .world-map {
    width: 120%;
    max-width: 700px;
    margin-left: -4vh;
    margin-bottom:1vh;
  }
  
  .project-filter {
    width: 50%;
    translate: 50%;
  }
  .milestone-title {
    margin-top: 2% !important;
  }    
  .progress-bars {
    width: 50%;
    translate: 50%;
    margin-top:3% !important;
    }
  .progress-bar-container {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .packagetab {
    width: 40%;
    translate: 74%;
    padding-top:35px !important;
  }
  .packagetabs-container {
    margin-top: 5%;
    gap:5px;
  }
  .packagetab.visible {
    margin-top:-2% !important;
  }
  .packagetab.visible .packagetab-details {
    max-height: 500px;
  }
  
  .packagetab.collapsed {
    height: 50px;
  }
  
  .packagetab-image {
    margin-bottom: 10px;
    margin-left: 0%;
    height: 360px;
    width: 85%;
  }
  
  .packagetab-text {
    width: 120%;
    font-size: 14px;
    margin-bottom: 10px;
    margin-left: -15%;
  }
  
  .package-footer {
    margin-top: 10px;
    padding-left:10%;
    padding-right:10%;
  }
  
  .package-price {
    font-size: 1.2em;
  }
  
  
  .packagetab-details {
    padding: 0;
  }
  
  .packagetab-details li {
    margin-bottom: 5px;
    font-size: 14px;
    padding-left:10%;
  }
  
  .packagetab-details li::before {
    width: 1em;
    margin-left: -1em;
  }
  
  .price-tab {
    font-size: 18px;
  }
  
  .donation-container:hover .heart {
    top: 21vh;
  }
  
  .donate-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  
  .donate-popup-content {
    width: 90%;
    max-width: 500px;
  }
  
  .close-button {
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
  }
  
  .donation-form .form-group {
    margin-bottom: 15px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  .contact-form-container {
    width: 40%;
    translate: 80%;
    }
}
@media (min-width: 1601px) and (max-width: 1700px) {
  .foreground-layer {
    background-size: 40vh;
    top:4vh;
  }
  
  .background-layer {
    height: 115vh;
  }
  
  .logo-container {
    margin-top: -3vh;
    height: 30vh;
  }
  
  .logobackground-layer {
    width: 110vw;
    height: 55vh;
    top: -5vh;
    background-position-y: 20%;
  }
  
  .logo {
    width: 35vh;
    margin-right: 3vh;
  }
  
  .title-values {
    font-size: 1.5rem;
    position: relative;
    top: 20vh;
  }
  .title-content {
    margin-top:15vh;
    width: 54%;
    translate: 45%;
    line-height: 40px;
    font-size: 20px;
  }
  .moreabout {
    margin-top: 10vh;
  }
  .aboutus {
    margin-top:12vh;
  }
  .programmes {
    height: 100px;
  }
  
  .programmes-title {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .programme-title {
    font-size: 1.5rem;
    left: -1vh;
    margin-bottom: 35px;
  }
    
  .programme-title::before {
    top: 55%;
    left: 33%;
    width: 23vh;
    height: 10vh;
  }
  .programme-titletext {
    margin-left: -4vh;
    top: 7vh;
    font-size: 1.2em;
  }
  .programme-item {
    font-size: 1.2rem;
    line-height: 1.2rem;
    width: 105%;
    margin-top: -5vh;
  }
  .programme-content {
    margin-bottom: 5vh;
    margin-top: -7vh;
  }
  .programme-image {
    width: 23vh; 
    margin-bottom: 10px;
    left: 2vh;
  }
  .programme-text {
    margin-bottom: 3vh;
    letter-spacing: 0.04em;
    margin-left: -8vh;
    font-size: 20px;
  }
  .donatenow {
    margin-top: 30vh;
  }
  .bridge-image {
    width: 100%;
    margin-top: 10%;
    height:400px;
  }
  
  .second-content {
    padding-top: 20vh;
  }
  
  .worldwide {
    margin-top: 50px;
  }
  .projects-title {
    margin-top: 5%;
    margin-bottom: 5%
  }
  .world-map {
    width: 120%;
    max-width: 700px;
    margin-left: -4vh;
    margin-bottom:1vh;
  }
  
  .project-filter {
    width: 50%;
    translate: 50%;
  }
  .milestone-title {
    margin-top: 2% !important;
  }    
  .progress-bars {
    width: 50%;
    translate: 50%;
    margin-top:3% !important;
  }
  .progress-bar-container {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .packagetab {
    width: 42%;
    translate: 70%;
    padding-top:35px !important;
  }
  .packagetabs-container {
    margin-top: 5%;
    gap:5px;
  }
  .packagetab.visible {
    margin-top:-2% !important;
  }
  .packagetab.visible .packagetab-details {
    max-height: 500px;
  }
  
  .packagetab.collapsed {
    height: 50px;
  }
  
  .packagetab-image {
    margin-bottom: 10px;
    margin-left: 0%;
    height: 360px;
    width: 85%;
  }
  
  .packagetab-text {
    width: 120%;
    font-size: 14px;
    margin-bottom: 10px;
    margin-left: -15%;
  }
  
  .package-footer {
    margin-top: 10px;
    padding-left:10%;
    padding-right:10%;  
  }
  
  .package-price {
    font-size: 1.2em;
  }
  
  
  .packagetab-details {
    padding: 0;
  }
  
  .packagetab-details li {
    margin-bottom: 5px;
    font-size: 14px;
    padding-left:10%;
  }
  
  .packagetab-details li::before {
    width: 1em;
    margin-left: -1em;
  }
  
  .price-tab {
    font-size: 18px;
  }
  
  .donation-container:hover .heart {
    top: 21vh;
  }
  
  .donate-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  
  .donate-popup-content {
    width: 90%;
    max-width: 500px;
  }
  
  .close-button {
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
  }
  
  .donation-form .form-group {
    margin-bottom: 15px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  .contact-form-container {
    width: 40%;
    translate: 80%;
    }
}
@media (min-width: 1501px) and (max-width: 1600px) {
  .foreground-layer {
    background-size: 40vh;
    top:4vh;
  }
  
  .background-layer {
    height: 115vh;
  }
  
  .logo-container {
    margin-top: -3vh;
    height: 30vh;
  }
  
  .logobackground-layer {
    width: 110vw;
    height: 55vh;
    top: -5vh;
    background-position-y: 20%;
  }
  
  .logo {
    width: 35vh;
    margin-right: 3vh;
  }
  
  .title-values {
    font-size: 1.5rem;
    position: relative;
    top: 20vh;
  }
  .title-content {
    margin-top:15vh;
    width: 61%;
    translate: 33%;
    line-height: 40px;
    font-size: 20px;
  }
  .moreabout {
    margin-top: 10vh;
  }
  .aboutus {
    margin-top:12vh;
  }
  .programmes {
    height: 100px;
  }
  
  .programmes-title {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .programme-title {
    font-size: 1.5rem;
    left: -1vh;
    margin-bottom: 35px;
  }
    
  .programme-title::before {
    top: 55%;
    left: 33%;
    width: 23vh;
    height: 10vh;
  }
  .programme-titletext {
    margin-left: -4vh;
    top: 7vh;
    font-size: 1.2em;
  }
  .programme-item {
    font-size: 1.2rem;
    line-height: 1.2rem;
    width: 105%;
    margin-top: -5vh;
  }
  .programme-content {
    margin-bottom: 5vh;
    margin-top: -7vh;
  }
  .programme-image {
    width: 23vh; 
    margin-bottom: 10px;
    left: 2vh;
  }
  .programme-text {
    margin-bottom: 3vh;
    letter-spacing: 0.04em;
    margin-left: -9vh;
    font-size: 20px;
  }
  .donatenow {
    margin-top: 30vh;
  }
  .bridge-image {
    width: 100%;
    margin-top: 10%;
    height:400px;
  }
  
  .second-content {
    padding-top: 20vh;
  }
  
  .worldwide {
    margin-top: 50px;
  }
  .projects-title {
    margin-top: 5%;
    margin-bottom: 5%
  }
  .world-map {
    width: 120%;
    max-width: 700px;
    margin-left: -4vh;
    margin-bottom:1vh;
  }
  
  .project-filter {
    width: 50%;
    translate: 50%;
  }
  .milestone-title {
    margin-top: 2% !important;
  }    
  .progress-bars {
    width: 50%;
    translate: 50%;
    margin-top:3% !important;
  }
  .progress-bar-container {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .packagetab {
    width: 45%;
    translate: 60%;
    padding-top:35px !important;
  }
  .packagetabs-container {
    margin-top: 5%;
    gap:5px;
  }
  .packagetab.visible {
    margin-top:-2% !important;
  }
  .packagetab.visible .packagetab-details {
    max-height: 500px;
  }
  
  .packagetab.collapsed {
    height: 50px;
  }
  
  .packagetab-image {
    margin-bottom: 10px;
    margin-left: 0%;
    height: 360px;
    width: 85%;
  }
  
  .packagetab-text {
    width: 120%;
    font-size: 14px;
    margin-bottom: 10px;
    margin-left: -15%;
  }
  
  .package-footer {
    margin-top: 10px;
    padding-left:10%;
    padding-right:10%;  
  }
  
  .package-price {
    font-size: 1.2em;
  }
  
  
  .packagetab-details {
    padding: 0;
  }
  
  .packagetab-details li {
    margin-bottom: 5px;
    font-size: 14px;
    padding-left:10%;
  }
  
  .packagetab-details li::before {
    width: 1em;
    margin-left: -1em;
  }
  
  .price-tab {
    font-size: 18px;
  }
  
  .donation-container:hover .heart {
    top: 21vh;
  }
  
  .donate-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  
  .donate-popup-content {
    width: 90%;
    max-width: 500px;
  }
  
  .close-button {
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
  }
  
  .donation-form .form-group {
    margin-bottom: 15px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  .contact-form-container {
    width: 40%;
    translate: 80%;
    }
}
@media (min-width: 1451px) and (max-width: 1500px) {
  .foreground-layer {
    background-size: 40vh;
    top:4vh;
  }
  
  .background-layer {
    height: 115vh;
  }
  
  .logo-container {
    margin-top: -3vh;
    height: 30vh;
  }
  
  .logobackground-layer {
    width: 110vw;
    height: 55vh;
    top: -5vh;
    background-position-y: 20%;
  }
  
  .logo {
    width: 35vh;
    margin-right: 3vh;
  }
  
  .title-values {
    font-size: 1.5rem;
    position: relative;
    top: 20vh;
  }
  .title-content {
    margin-top:15vh;
    width: 61%;
    translate: 33%;
    line-height: 40px;
    font-size: 20px;
  }
  .moreabout {
    margin-top: 10vh;
  }
  .aboutus {
    margin-top:12vh;
  }
  .programmes {
    height: 100px;
  }
  
  .programmes-title {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .programme-title {
    font-size: 1.5rem;
    left: -1vh;
    margin-bottom: 35px;
  }
    
  .programme-title::before {
    top: 55%;
    left: 33%;
    width: 23vh;
    height: 10vh;
  }
  .programme-titletext {
    margin-left: -4vh;
    top: -2vh;
    font-size: 1.2em;
  }
  .programme-item {
    font-size: 1.2rem;
    line-height: 1.2rem;
    width: 105%;
    margin-top: -5vh;
  }
  .programme-content {
    margin-bottom: 5vh;
    margin-top: -7vh;
  }
  .programme-image {
    width: 23vh; 
    margin-bottom: 10px;
    left: 2vh;
  }
  .programme-text {
    margin-bottom: 3vh;
    letter-spacing: 0.04em;
    margin-left: -7vh;
    font-size: 20px;
  }
  .donatenow {
    margin-top: 30vh;
  }
  .bridge-image {
    width: 100%;
    margin-top: 10%;
    height:400px;
  }
  
  .second-content {
    padding-top: 20vh;
  }
  
  .worldwide {
    margin-top: 50px;
  }
  .projects-title {
    margin-top: 5%;
    margin-bottom: 5%
  }
  .world-map {
    width: 120%;
    max-width: 700px;
    margin-left: -4vh;
    margin-bottom:1vh;
  }
  
  .project-filter {
    width: 50%;
    translate: 50%;
  }
  .milestone-title {
    margin-top: 2% !important;
  }   
  .progress-bars {
    width: 50%;
    translate: 50%;
    margin-top:3% !important;
  }
  .progress-bar-container {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .packagetab {
    width: 45%;
    translate: 60%;
    padding-top:35px !important;
  }
  .packagetabs-container {
    margin-top: 5%;
    gap:5px;
  }
  .packagetab.visible {
    margin-top:-4% !important;
  }
  .packagetab.visible .packagetab-details {
    max-height: 500px;
  }
  
  .packagetab.collapsed {
    height: 50px;
  }
  
  .packagetab-image {
    margin-bottom: 10px;
    margin-left: 0%;
    height: 300px;
    width: 85%;
  }
  
  .packagetab-text {
    width: 120%;
    font-size: 14px;
    margin-bottom: 10px;
    margin-left: -15%;
  }
  
  .package-footer {
    margin-top: 10px;
    padding-left:10%;
    padding-right:10%;  
  }

  .package-price {
    font-size: 1.2em;
  }
  
  
  .packagetab-details {
    padding: 0;
  }
  
  .packagetab-details li {
    margin-bottom: 5px;
    font-size: 14px;
    padding-left:10%;
  }
  
  .packagetab-details li::before {
    width: 1em;
    margin-left: -1em;
  }
  
  .price-tab {
    font-size: 18px;
  }
  
  .donation-container:hover .heart {
    top: 21vh;
  }
  
  .donate-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  
  .donate-popup-content {
    width: 90%;
    max-width: 500px;
  }
  
  .close-button {
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
  }
  
  .donation-form .form-group {
    margin-bottom: 15px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  .contact-form-container {
    width: 40%;
    translate: 80%;
    }
}
@media (min-width: 1401px) and (max-width: 1450px) {
  .foreground-layer {
    background-size: 40vh;
    top:4vh;
  }
  
  .background-layer {
    height: 115vh;
  }
  
  .logo-container {
    margin-top: -3vh;
    height: 30vh;
  }
  
  .logobackground-layer {
    width: 110vw;
    height: 55vh;
    top: -5vh;
    background-position-y: 20%;
  }
  
  .logo {
    width: 35vh;
    margin-right: 3vh;
  }
  
  .title-values {
    font-size: 1.5rem;
    position: relative;
    top: 20vh;
  }
  .title-content {
    margin-top:15vh;
    width: 61%;
    translate: 33%;
    line-height: 40px;
    font-size: 20px;
  }
  .moreabout {
    margin-top: 10vh;
  }
  .aboutus {
    margin-top:12vh;
  }
  .programmes {
    height: 100px;
  }
  
  .programmes-title {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .programme-title {
    font-size: 1.5rem;
    left: -1vh;
    margin-bottom: 35px;
  }
    
  .programme-title::before {
    top: 55%;
    left: 33%;
    width: 23vh;
    height: 10vh;
  }
  .programme-titletext {
    margin-left: -4vh;
    top: -2vh;
    font-size: 1.2em;
  }
  .programme-item {
    font-size: 1.2rem;
    line-height: 1.2rem;
    width: 105%;
    margin-top: -5vh;
  }
  .programme-content {
    margin-bottom: 5vh;
    margin-top: -7vh;
  }
  .programme-image {
    width: 23vh; 
    margin-bottom: 10px;
    left: 2vh;
  }
  .programme-text {
    margin-bottom: 3vh;
    letter-spacing: 0.04em;
    margin-left: -7vh;
  }
  .donatenow {
    margin-top: 30vh;
  }
  .bridge-image {
    width: 100%;
    margin-top: 10%;
    height:400px;
  }
  
  .second-content {
    padding-top: 20vh;
  }
  
  .worldwide {
    margin-top: 50px;
  }
  .projects-title {
    margin-top: 5%;
    margin-bottom: 5%
  }
  .world-map {
    width: 120%;
    max-width: 700px;
    margin-left: -4vh;
    margin-bottom:1vh;
  }
  
  .project-filter {
    width: 50%;
    translate: 50%;
  }
  .milestone-title {
    margin-top: 2% !important;
  }   
  .progress-bars {
    width: 50%;
    translate: 50%;
    margin-top:3% !important;
  }
  .progress-bar-container {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .packagetab {
    width: 45%;
    translate: 60%;
    padding-top:35px !important;
  }
  .packagetabs-container {
    margin-top: 5%;
    gap:5px;
  }
  .packagetab.visible {
    margin-top:-4% !important;
  }
  .packagetab.visible .packagetab-details {
    max-height: 500px;
  }
  
  .packagetab.collapsed {
    height: 50px;
  }
  
  .packagetab-image {
    margin-bottom: 10px;
    margin-left: 0%;
    height: 300px;
    width: 85%;
  }
  
  .packagetab-text {
    width: 120%;
    font-size: 14px;
    margin-bottom: 10px;
    margin-left: -15%;
  }
  
  .package-footer {
    margin-top: 10px;
    padding-left:10%;
    padding-right:10%;  
  }
  
  .package-price {
    font-size: 1.2em;
  }
  
  
  .packagetab-details {
    padding: 0;
  }
  
  .packagetab-details li {
    margin-bottom: 5px;
    font-size: 14px;
    padding-left:10%;
  }
  
  .packagetab-details li::before {
    width: 1em;
    margin-left: -1em;
  }
  
  .price-tab {
    font-size: 18px;
  }
  
  .donation-container:hover .heart {
    top: 21vh;
  }
  
  .donate-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  
  .donate-popup-content {
    width: 90%;
    max-width: 500px;
  }
  
  .close-button {
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
  }
  
  .donation-form .form-group {
    margin-bottom: 15px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  .contact-form-container {
    width: 40%;
    translate: 75%;
    }
}  
@media (min-width: 1351px) and (max-width: 1400px) {
  .foreground-layer {
    background-size: 40vh;
    top:4vh;
  }
  
  .background-layer {
    height: 115vh;
  }
  
  .logo-container {
    margin-top: -3vh;
    height: 30vh;
  }
  
  .logobackground-layer {
    width: 110vw;
    height: 55vh;
    top: -5vh;
    background-position-y: 20%;
  }
  
  .logo {
    width: 35vh;
    margin-right: 3vh;
  }
  
  .title-values {
    font-size: 1.5rem;
    position: relative;
    top: 20vh;
  }
  .title-content {
    margin-top:15vh;
    width: 64%;
    translate: 30%;
    line-height: 40px;
    font-size: 20px;
  }
  .moreabout {
    margin-top: 10vh;
  }
  .aboutus {
    margin-top:12vh;
  }
  .programmes {
    height: 100px;
  }
  
  .programmes-title {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .programme-title {
    font-size: 1.5rem;
    left: -1vh;
    margin-bottom: 35px;
  }
    
  .programme-title::before {
    top: 55%;
    left: 33%;
    width: 23vh;
    height: 10vh;
  }
  .programme-titletext {
    margin-left: -4vh;
    top: -2vh;
    font-size: 1.2em;
  }
  .programme-item {
    font-size: 1.2rem;
    line-height: 1.2rem;
    width: 105%;
    margin-top: -5vh;
  }
  .programme-content {
    margin-bottom: 5vh;
    margin-top: -7vh;
  }
  .programme-image {
    width: 23vh; 
    margin-bottom: 10px;
    left: 2vh;
  }
  .programme-text {
    margin-bottom: 3vh;
    letter-spacing: 0.04em;
    margin-left: -7vh;
  }
  .donatenow {
    margin-top: 30vh;
  }
  .bridge-image {
    width: 100%;
    margin-top: 10%;
    height:400px;
  }
  
  .second-content {
    padding-top: 20vh;
  }
  
  .worldwide {
    margin-top: 50px;
  }
  .projects-title {
    margin-top: 5%;
    margin-bottom: 5%
  }
  .world-map {
    width: 120%;
    max-width: 700px;
    margin-left: -4vh;
    margin-bottom:1vh;
  }
  
  .project-filter {
    width: 50%;
    translate: 50%;
  }
  .milestone-title {
    margin-top: 2% !important;
  }   
  .progress-bars {
    width: 50%;
    translate: 50%;
    margin-top:3% !important;
  }
  .progress-bar-container {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .packagetab {
    width: 45%;
    translate: 60%;
    padding-top:35px !important;
  }
  .packagetabs-container {
    margin-top: 5%;
    gap:5px;
  }
  .packagetab.visible {
    margin-top:-4% !important;
  }
  .packagetab.visible .packagetab-details {
    max-height: 500px;
  }
  
  .packagetab.collapsed {
    height: 50px;
  }
  
  .packagetab-image {
    margin-bottom: 10px;
    margin-left: 0%;
    height: 300px;
    width: 85%;
  }
  
  .packagetab-text {
    width: 120%;
    font-size: 14px;
    margin-bottom: 10px;
    margin-left: -15%;
  }
  
  .package-footer {
    margin-top: 10px;
    padding-left:10%;
    padding-right:10%;  
  }
  .package-price {
    font-size: 1.2em;
  }
  
  
  .packagetab-details {
    padding: 0;
  }
  
  .packagetab-details li {
    margin-bottom: 5px;
    font-size: 14px;
  }
  
  .packagetab-details li::before {
    width: 1em;
    margin-left: -1em;
  }
  
  .price-tab {
    font-size: 18px;
  }
  
  .donation-container:hover .heart {
    top: 21vh;
  }
  
  .donate-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  
  .donate-popup-content {
    width: 90%;
    max-width: 500px;
  }
  
  .close-button {
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
  }
  
  .donation-form .form-group {
    margin-bottom: 15px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  .contact-form-container {
    width: 40%;
    translate: 75%;
    }
}
@media (min-width: 1301px) and (max-width: 1350px) {
  .foreground-layer {
    background-size: 40vh;
    top:4vh;
  }
  
  .background-layer {
    height: 115vh;
  }
  
  .logo-container {
    margin-top: -3vh;
    height: 30vh;
  }
  
  .logobackground-layer {
    width: 110vw;
    height: 55vh;
    top: -5vh;
    background-position-y: 20%;
  }
  
  .logo {
    width: 35vh;
    margin-right: 3vh;
  }
  
  .title-values {
    font-size: 1.5rem;
    position: relative;
    top: 20vh;
  }
  .title-content {
    margin-top:15vh;
    width: 65%;
    translate: 30%;
    line-height: 40px;
    font-size: 20px;
  }
  .moreabout {
    margin-top: 10vh;
  }
  .aboutus {
    margin-top:12vh;
  }
  .programmes {
    height: 100px;
  }
  
  .programmes-title {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .programme-title {
    font-size: 1.5rem;
    left: -1vh;
    margin-bottom: 35px;
  }
    
  .programme-title::before {
    top: 55%;
    left: 33%;
    width: 23vh;
    height: 10vh;
  }
  .programme-titletext {
    margin-left: -4vh;
    top: -2vh;
    font-size: 1.2em;
  }
  .programme-item {
    font-size: 1.2rem;
    line-height: 1.2rem;
    width: 105%;
    margin-top: -5vh;
  }
  .programme-content {
    margin-bottom: 5vh;
    margin-top: -7vh;
  }
  .programme-image {
    width: 23vh; 
    margin-bottom: 10px;
    left: 2vh;
  }
  .programme-text {
    margin-bottom: 3vh;
    letter-spacing: 0.04em;
    margin-left: -7vh;
  }
  .donatenow {
    margin-top: 30vh;
  }
  .bridge-image {
    width: 100%;
    margin-top: 10%;
    height:400px;
  }
  
  .second-content {
    padding-top: 20vh;
  }
  
  .worldwide {
    margin-top: 50px;
  }
  .projects-title {
    margin-top: 5%;
    margin-bottom: 5%
  }
  .world-map {
    width: 120%;
    max-width: 700px;
    margin-left: -4vh;
    margin-bottom:1vh;
  }
  
  .project-filter {
    width: 50%;
    translate: 50%;
  }
  .milestone-title {
    margin-top: 2% !important;
  }   
  .progress-bars {
    width: 50%;
    translate: 50%;
    margin-top:3% !important;
  }
  .progress-bar-container {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .packagetab {
    width: 45%;
    translate: 60%;
    padding-top:35px !important;
  }
  .packagetabs-container {
    margin-top: 5%;
    gap:5px;
  }
  .packagetab.visible {
    margin-top:-4% !important;
  }
  .packagetab.visible .packagetab-details {
    max-height: 500px;
  }
  
  .packagetab.collapsed {
    height: 50px;
  }
  
  .packagetab-image {
    margin-bottom: 10px;
    margin-left: 0%;
    height: 300px;
    width: 85%;
  }
  
  .packagetab-text {
    width: 120%;
    font-size: 14px;
    margin-bottom: 10px;
    margin-left: -15%;
  }
  
  .package-footer {
    margin-top: 10px;
    padding-left:10%;
    padding-right:10%;  
  }
  .package-price {
    font-size: 1.2em;
  }
  
  
  .packagetab-details {
    padding: 0;
  }
  
  .packagetab-details li {
    margin-bottom: 5px;
    font-size: 14px;
  }
  
  .packagetab-details li::before {
    width: 1em;
    margin-left: -1em;
  }
  
  .price-tab {
    font-size: 18px;
  }
  
  .donation-container:hover .heart {
    top: 21vh;
  }
  
  .donate-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  
  .donate-popup-content {
    width: 90%;
    max-width: 500px;
  }
  
  .close-button {
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
  }
  
  .donation-form .form-group {
    margin-bottom: 15px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  .contact-form-container {
    width: 40%;
    translate: 75%;
    }
}
@media (min-width: 1251px) and (max-width: 1300px) {
  .foreground-layer {
    background-size: 40vh;
    top:4vh;
  }
  
  .background-layer {
    height: 115vh;
  }
  
  .logo-container {
    margin-top: -3vh;
    height: 30vh;
  }

  .logobackground-layer {
    width: 110vw;
    height: 55vh;
    top: -5vh;
    background-position-y: 20%;
  }
  
  .logo {
    width: 35vh;
    margin-right: 3vh;
  }
  
  .title-values {
    font-size: 1.5rem;
    top: 20vh;
  }
  .title-content {
    margin-top:15vh;
    width: 75%;
    translate: 19%;
    line-height: 40px;
    font-size: 20px;
  }
  .moreabout {
    margin-top: 10vh;
  }
  .aboutus {
    margin-top:12vh;
  }
  .programmes {
    height: 100px;
  }
  
  .programmes-title {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .programme-title {
    font-size: 1.5rem;
    left: -1vh;
    margin-bottom: 35px;
  }
    
  .programme-title::before {
    top: 55%;
    left: 33%;
    width: 23vh;
    height: 10vh;
  }
  .programme-titletext {
    margin-left: -4vh;
    top: -2vh;
    font-size: 1.2em;
  }
  .programme-item {
    font-size: 1.2rem;
    line-height: 1.2rem;
    width: 105%;
    margin-top: -5vh;
  }
  .programme-content {
    margin-bottom: 5vh;
    margin-top: -7vh;
  }
  .programme-image {
    width: 23vh; 
    margin-bottom: 10px;
    left: 2vh;
  }
  .programme-text {
    margin-bottom: 3vh;
    letter-spacing: 0.04em;
    margin-left: -7vh;
  }
  .donatenow {
    margin-top: 30vh;
  }
  .bridge-image {
    width: 100%;
    margin-top: 10%;
    height:350px;
  }
  
  .second-content {
    padding-top: 20vh;
  }
  
  .worldwide {
    margin-top: 50px;
  }
  .projects-title {
    margin-top: 5%;
    margin-bottom: 5%
  }
  .world-map {
    width: 120%;
    max-width: 700px;
    margin-left: -4vh;
    margin-bottom:1vh;
  }
  
  .project-filter {
    width: 50%;
    translate: 50%;
  }
  .milestone-title {
    margin-top: 2% !important;
  }   
  .progress-bars {
    width: 50%;
    translate: 50%;
    margin-top:3% !important;
  }
  .progress-bar-container {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .packagetab {
    width: 45%;
    translate: 60%;
    padding-top:35px !important;
  }
  .packagetabs-container {
    margin-top: 5%;
    gap:5px;
  }
  .packagetab.visible {
    margin-top:-4% !important;
  }
  .packagetab.visible .packagetab-details {
    max-height: 500px;
  }
  
  .packagetab.collapsed {
    height: 50px;
  }
  
  .packagetab-image {
    margin-bottom: 10px;
    margin-left: 0%;
    height: 300px;
    width: 85%;
  }
  
  .packagetab-text {
    width: 120%;
    font-size: 14px;
    margin-bottom: 0px;
    margin-left: -15%;
  }
  
  .package-footer {
    margin-top: 10px;
  }
  .package-price {
    font-size: 1.2em;
  }
  
  
  .packagetab-details {
    padding: 0;
  }
  
  .packagetab-details li {
    margin-bottom: 5px;
    font-size: 14px;
  }
  
  .packagetab-details li::before {
    width: 1em;
    margin-left: -1em;
  }
  
  .price-tab {
    font-size: 18px;
  }
  
  .donation-container:hover .heart {
    top: 21vh;
  }
  
  .donate-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  
  .donate-popup-content {
    width: 90%;
    max-width: 500px;
  }
  
  .close-button {
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
  }
  
  .donation-form .form-group {
    margin-bottom: 15px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  .contact-form-container {
    width: 40%;
    translate: 75%;
    }
}
@media (min-width: 1201px) and (max-width: 1250px) {
  .foreground-layer {
    background-size: 40vh;
    top:4vh;
  }
  
  .background-layer {
    height: 115vh;
  }
  
  .logo-container {
    margin-top: -3vh;
    height: 30vh;
  }

  .logobackground-layer {
    width: 110vw;
    height: 55vh;
    top: -5vh;
    background-position-y: 20%;
  }
  
  .logo {
    width: 35vh;
    margin-right: 3vh;
  }
  
  .title-values {
    font-size: 1.5rem;
    top: 20vh;
  }
  .title-content {
    margin-top:15vh;
    width: 75%;
    translate: 19%;
    line-height: 40px;
    font-size: 20px;
  }
  .moreabout {
    margin-top: 10vh;
  }
  .aboutus {
    margin-top:12vh;
  }
  .programmes {
    height: 100px;
  }
  
  .programmes-title {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .programme-title {
    font-size: 1.5rem;
    left: -1vh;
    margin-bottom: 35px;
  }
    
  .programme-title::before {
    top: 55%;
    left: 33%;
    width: 23vh;
    height: 10vh;
  }
  .programme-titletext {
    margin-left: -4vh;
    top: -2vh;
    font-size: 1.2em;
  }
  .programme-item {
    font-size: 1.2rem;
    line-height: 1.2rem;
    width: 105%;
    margin-top: -5vh;
  }
  .programme-content {
    margin-bottom: 5vh;
    margin-top: -7vh;
  }
  .programme-image {
    width: 23vh; 
    margin-bottom: 10px;
    left: 2vh;
  }
  .programme-text {
    margin-bottom: 3vh;
    letter-spacing: 0.04em;
    margin-left: -7vh;
  }
  .donatenow {
    margin-top: 30vh;
  }
  .bridge-image {
    width: 100%;
    margin-top: 10%;
    height:350px;
  }
  
  .second-content {
    padding-top: 20vh;
  }
  
  .worldwide {
    margin-top: 50px;
  }
  .projects-title {
    margin-top: 5%;
    margin-bottom: 5%
  }
  .world-map {
    width: 120%;
    max-width: 700px;
    margin-left: -4vh;
    margin-bottom:1vh;
  }
  
  .project-filter {
    width: 50%;
    translate: 50%;
  }
  .milestone-title {
    margin-top: 2% !important;
  }   
  .progress-bars {
    width: 50%;
    translate: 50%;
    margin-top:3% !important;
  }
  .progress-bar-container {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .packagetab {
    width: 45%;
    translate: 60%;
    padding-top:35px !important;
  }
  .packagetabs-container {
    margin-top: 5%;
    gap:5px;
  }
  .packagetab.visible {
    margin-top:-4% !important;
  }
  .packagetab.visible .packagetab-details {
    max-height: 500px;
  }
  
  .packagetab.collapsed {
    height: 50px;
  }
  
  .packagetab-image {
    margin-bottom: 10px;
    margin-left: 0%;
    height: 300px;
    width: 85%;
  }
  
  .packagetab-text {
    width: 120%;
    font-size: 14px;
    margin-bottom: 0px;
    margin-left: -15%;
  }
  
  .package-footer {
    margin-top: 10px;
  }
  .package-price {
    font-size: 1.2em;
  }
  
  
  .packagetab-details {
    padding: 0;
  }
  
  .packagetab-details li {
    margin-bottom: 5px;
    font-size: 14px;
  }
  
  .packagetab-details li::before {
    width: 1em;
    margin-left: -1em;
  }
  
  .price-tab {
    font-size: 18px;
  }
  
  .donation-container:hover .heart {
    top: 21vh;
  }
  
  .donate-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  
  .donate-popup-content {
    width: 90%;
    max-width: 500px;
  }
  
  .close-button {
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
  }
  
  .donation-form .form-group {
    margin-bottom: 15px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  .contact-form-container {
    width: 40%;
    translate: 75%;
    }
}
@media (min-width: 1151px) and (max-width: 1200px) {
  .foreground-layer {
    background-size: 40vh;
    top:4vh;
  }
  
  .background-layer {
    height: 115vh;
  }
  
  .logo-container {
    margin-top: -3vh;
    height: 30vh;
  }

  .logobackground-layer {
    width: 110vw;
    height: 55vh;
    top: -5vh;
    background-position-y: 20%;
  }
  
  .logo {
    width: 35vh;
    margin-right: 3vh;
  }
  
  .title-values {
    font-size: 1.5rem;
    top: 20vh;
  }
  .title-content {
    margin-top:15vh;
    width: 75%;
    translate: 19%;
    line-height: 40px;
    font-size: 20px;
  }
  .moreabout {
    margin-top: 10vh;
  }
  .aboutus {
    margin-top:12vh;
  }
  .programmes {
    height: 100px;
  }
  
  .programmes-title {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .programme-title {
    font-size: 1.5rem;
    left: -1vh;
    margin-bottom: 35px;
  }
    
  .programme-title::before {
    top: 55%;
    left: 33%;
    width: 23vh;
    height: 10vh;
  }
  .programme-titletext {
    margin-left: -4vh;
    top: -2vh;
    font-size: 1.2em;
  }
  .programme-item {
    font-size: 1.2rem;
    line-height: 1.2rem;
    width: 70%;
    margin-top: -5vh;
    translate:27%;
  }
  .programme-content {
    margin-bottom: 5vh;
    margin-top: -7vh;
  }
  .programme-image {
    width: 23vh; 
    margin-bottom: 10px;
    left: 2vh;
  }
  .programme-text {
    margin-bottom: 3vh;
    letter-spacing: 0.04em;
    margin-left: -7vh;
  }
  .donatenow {
    margin-top: 30vh;
  }
  .bridge-image {
    width: 100%;
    margin-top: 10%;
    height:300px;
  }
  
  .second-content {
    padding-top: 20vh;
  }
  
  .worldwide {
    margin-top: 50px;
  }
  .projects-title {
    margin-top: 5%;
    margin-bottom: 5%
  }
  .world-map {
    width: 120%;
    max-width: 700px;
    margin-left: -4vh;
    margin-bottom:1vh;
  }
  
  .project-filter {
    width: 50%;
    translate: 50%;
  }
  .milestone-title {
    margin-top: 2% !important;
  }   
  .progress-bars {
    width: 50%;
    translate: 50%;
    margin-top:3% !important;
  }
  .progress-bar-container {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .packagetab {
    width: 45%;
    translate: 60%;
    padding-top:35px !important;
  }
  .packagetabs-container {
    margin-top: 5%;
    gap:5px;
  }
  .packagetab.visible {
    margin-top:-4% !important;
  }
  .packagetab.visible .packagetab-details {
    max-height: 500px;
  }
  
  .packagetab.collapsed {
    height: 50px;
  }
  
  .packagetab-image {
    margin-bottom: 10px;
    margin-left: 0%;
    height: 300px;
    width: 85%;
  }
  
  .packagetab-text {
    width: 120%;
    font-size: 14px;
    margin-bottom: 0px;
    margin-left: -15%;
  }
  
  .package-footer {
    margin-top: 10px;
  }
  .package-price {
    font-size: 1.2em;
  }
  
  
  .packagetab-details {
    padding: 0;
  }
  
  .packagetab-details li {
    margin-bottom: 5px;
    font-size: 14px;
  }
  
  .packagetab-details li::before {
    width: 1em;
    margin-left: -1em;
  }
  
  .price-tab {
    font-size: 18px;
  }
  
  .donation-container:hover .heart {
    top: 21vh;
  }
  
  .donate-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  
  .donate-popup-content {
    width: 90%;
    max-width: 500px;
  }
  
  .close-button {
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
  }
  
  .donation-form .form-group {
    margin-bottom: 15px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
.contact-form-container {
  width: 40%;
  translate: 75%;
  }
}
@media (min-width: 1101px) and (max-width: 1150px) {
  .foreground-layer {
    background-size: 40vh;
    top:4vh;
  }
  
  .background-layer {
    height: 115vh;
  }
  
  .logo-container {
    margin-top: -3vh;
    height: 30vh;
  }
  
  .logobackground-layer {
    width: 110vw;
    height: 55vh;
    top: -5vh;
    background-position-y: 20%;
  }
  
  .logo {
    width: 35vh;
    margin-right: 3vh;
  }
  
  .title-values {
    font-size: 1.5rem;
    top: 20vh;
  }
  .title-content {
    margin-top:15vh;
    width: 80%;
    translate: 13%;
    line-height: 40px;
    font-size: 20px;
  }
  .moreabout {
    margin-top: 10vh;
  }
  .aboutus {
    margin-top:12vh;
  }
  .programmes {
    height: 100px;
  }
  
  .programmes-title {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .programme-title {
    font-size: 1.5rem;
    left: -1vh;
    margin-bottom: 35px;
  }
    
  .programme-title::before {
    top: 55%;
    left: 33%;
    width: 23vh;
    height: 10vh;
  }
  .programme-titletext {
    margin-left: -4vh;
    top: -2vh;
    font-size: 1.2em;
  }
  .programme-item {
    font-size: 1.2rem;
    line-height: 1.2rem;
    width: 75%;
    margin-top: -5vh;
    translate:21%;
  }
  .programme-content {
    margin-bottom: 5vh;
    margin-top: -7vh;
  }
  .programme-image {
    width: 23vh; 
    margin-bottom: 10px;
    left: 2vh;
  }
  .programme-text {
    margin-bottom: 3vh;
    letter-spacing: 0.04em;
    margin-left: -7vh;
  }
  .donatenow {
    margin-top: 30vh;
  }
  .bridge-image {
    width: 100%;
    margin-top: 10%;
    height:300px;
  }
  
  .second-content {
    padding-top: 20vh;
  }
  
  .worldwide {
    margin-top: 50px;
  }
  .projects-title {
    margin-top: 5%;
    margin-bottom: 5%
  }
  .world-map {
    width: 120%;
    max-width: 700px;
    margin-left: -4vh;
    margin-bottom:1vh;
  }
  
  .project-filter {
    width: 70%;
    translate: 20%;
  }
  .milestone-title {
    margin-top: 2% !important;
  }   
  .progress-bars {
    width: 70%;
    translate: 20%;
    margin-top:3% !important;
  }
  .progress-bar-container {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .packagetab {
    width: 50%;
    translate:45%;
    padding-top:35px !important;
  }
  .packagetabs-container {
    margin-top: 5%;
    gap:5px;
  }
  .packagetab.visible {
    margin-top:-4% !important;
  }
  .packagetab.visible .packagetab-details {
    max-height: 500px;
  }
  
  .packagetab.collapsed {
    height: 50px;
  }
  
  .packagetab-image {
    margin-bottom: 10px;
    margin-left: 0%;
    height: 300px;
    width: 85%;
  }
  
  .packagetab-text {
    width: 120%;
    font-size: 14px;
    margin-bottom: 0px;
    margin-left: -15%;
  }
  
  .package-footer {
    margin-top: 10px;
  }
  .package-price {
    font-size: 1.2em;
  }
  
  
  .packagetab-details {
    padding: 0;
  }
  
  .packagetab-details li {
    margin-bottom: 5px;
    font-size: 14px;
  }
  
  .packagetab-details li::before {
    width: 1em;
    margin-left: -1em;
  }
  
  .price-tab {
    font-size: 18px;
  }
  
  .donation-container:hover .heart {
    top: 21vh;
  }
  
  .donate-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  
  .donate-popup-content {
    width: 90%;
    max-width: 500px;
  }
  
  .close-button {
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
  }
  
  .donation-form .form-group {
    margin-bottom: 15px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  .contact-form-container {
    width: 50%;
    translate: 50%;
    }
}
@media (min-width: 1051px) and (max-width: 1100px) {
  .foreground-layer {
    background-size: 40vh;
    top:4vh;
  }
  
  .background-layer {
    height: 115vh;
  }
  
  .logo-container {
    margin-top: -3vh;
    height: 30vh;
  }

  .logobackground-layer {
    width: 110vw;
    height: 55vh;
    top: -5vh;
    background-position-y: 30%;
  }
  
  .logo {
    width: 35vh;
    margin-right: 3vh;
  }
  
  .title-values {
    font-size: 1.5rem;
    top: 20vh;
  }
  .title-content {
    margin-top:15vh;
    width: 82%;
    translate: 13%;
    line-height: 40px;
    font-size: 20px;
  }
  .moreabout {
    margin-top: 10vh;
  }
  .aboutus {
    margin-top:12vh;
  }
  .programmes {
    height: 100px;
  }
  
  .programmes-title {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .programme-title {
    font-size: 1.5rem;
    left: -1vh;
    margin-bottom: 35px;
  }
    
  .programme-title::before {
    top: 55%;
    left: 33%;
    width: 23vh;
    height: 10vh;
  }
  .programme-titletext {
    margin-left: -4vh;
    top: -2vh;
    font-size: 1.2em;
  }
  .programme-item {
    font-size: 1.2rem;
    line-height: 1.2rem;
    width: 77%;
    margin-top: -5vh;
    translate: 18%;
  }
  .programme-content {
    margin-bottom: 5vh;
    margin-top: -7vh;
  }
  .programme-image {
    width: 23vh; 
    margin-bottom: 10px;
    left: 2vh;
  }
  .programme-text {
    margin-bottom: 3vh;
    letter-spacing: 0.04em;
    margin-left: -7vh;
  }
  .donatenow {
    margin-top: 30vh;
  }
  .bridge-image {
    width: 100%;
    margin-top: 10%;
    height:300px;
  }
  
  .second-content {
    padding-top: 20vh;
  }
  
  .worldwide {
    margin-top: 50px;
  }
  .projects-title {
    margin-top: 5%;
    margin-bottom: 5%
  }
  .world-map {
    width: 120%;
    max-width: 700px;
    margin-left: -4vh;
    margin-bottom:1vh;
  }
  
  .project-filter {
    width: 70%;
    translate: 20%;
  }
  .milestone-title {
    margin-top: 2% !important;
  }   
  .progress-bars {
    width: 70%;
    translate: 20%;
    margin-top:3% !important;
  }
  .progress-bar-container {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .packagetab {
    width: 50%;
    translate:45%;
    padding-top:35px !important;
  }
  .packagetabs-container {
    margin-top: 5%;
    gap:5px;
  }
  .packagetab.visible {
    margin-top:-4% !important;
  }
  .packagetab.visible .packagetab-details {
    max-height: 500px;
  }
  
  .packagetab.collapsed {
    height: 50px;
  }
  
  .packagetab-image {
    margin-bottom: 10px;
    margin-left: 0%;
    height: 300px;
    width: 85%;
  }
  
  .packagetab-text {
    width: 120%;
    font-size: 14px;
    margin-bottom: 0px;
    margin-left: -15%;
  }
  
  .package-footer {
    margin-top: 10px;
  }
  .package-price {
    font-size: 1.2em;
  }
  
  
  .packagetab-details {
    padding: 0;
  }
  
  .packagetab-details li {
    margin-bottom: 5px;
    font-size: 14px;
  }
  
  .packagetab-details li::before {
    width: 1em;
    margin-left: -1em;
  }
  
  .price-tab {
    font-size: 18px;
  }
  
  .donation-container:hover .heart {
    top: 21vh;
  }
  
  .donate-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  
  .donate-popup-content {
    width: 90%;
    max-width: 500px;
  }
  
  .close-button {
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
  }
  
  .donation-form .form-group {
    margin-bottom: 15px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  .contact-form-container {
    width: 50%;
    translate: 50%;
    }
}
@media (min-width: 1001px) and (max-width: 1050px) {
  .foreground-layer {
    background-size: 40vh;
    top:4vh;
  }
  
  .background-layer {
    height: 115vh;
  }
  
  .logo-container {
    margin-top: -3vh;
    height: 30vh;
  }

  .logobackground-layer {
    width: 110vw;
    height: 55vh;
    top: -5vh;
    background-position-y: 30%;
  }
  
  .logo {
    width: 35vh;
    margin-right: 3vh;
  }
  
  .title-values {
    font-size: 1.5rem;
    top: 20vh;
  }
  .title-content {
    margin-top:15vh;
    width: 85%;
    translate: 10%;
    line-height: 40px;
    font-size: 20px;
  }
  .moreabout {
    margin-top: 10vh;
  }
  .aboutus {
    margin-top:12vh;
  }
  .programmes {
    height: 100px;
  }
  
  .programmes-title {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .programme-title {
    font-size: 1.5rem;
    left: -1vh;
    margin-bottom: 35px;
  }
    
  .programme-title::before {
    top: 55%;
    left: 33%;
    width: 23vh;
    height: 10vh;
  }
  .programme-titletext {
    margin-left: -4vh;
    top: -2vh;
    font-size: 1.2em;
  }
  .programme-item {
    font-size: 1.2rem;
    line-height: 1.2rem;
    width: 81%;
    margin-top: -5vh;
    translate: 15%;
  }
  .programme-content {
    margin-bottom: 5vh;
    margin-top: -7vh;
  }
  .programme-image {
    width: 23vh; 
    margin-bottom: 10px;
    left: 2vh;
  }
  .programme-text {
    margin-bottom: 3vh;
    letter-spacing: 0.04em;
    margin-left: -7vh;
  }
  .donatenow {
    margin-top: 30vh;
  }
  .bridge-image {
    width: 100%;
    margin-top: 10%;
    height:300px;
  }
  
  .second-content {
    padding-top: 20vh;
  }
  
  .worldwide {
    margin-top: 50px;
  }
  .projects-title {
    margin-top: 5%;
    margin-bottom: 5%
  }
  .world-map {
    width: 120%;
    max-width: 700px;
    margin-left: -4vh;
    margin-bottom:1vh;
  }
  
  .project-filter {
    width: 70%;
    translate: 20%;
  }
  .milestone-title {
    margin-top: 2% !important;
  }   
  .progress-bars {
    width: 70%;
    translate: 20%;
    margin-top:3% !important;
  }
  .progress-bar-container {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .packagetab {
    width: 55%;
    translate:40%;
    padding-top:35px !important;
  }
  .packagetabs-container {
    margin-top: 5%;
    gap:5px;
  }
  .packagetab.visible {
    margin-top:-5% !important;
  }
  .packagetab.visible .packagetab-details {
    max-height: 500px;
  }
  
  .packagetab.collapsed {
    height: 50px;
  }
  
  .packagetab-image {
    margin-bottom: 10px;
    margin-left: 0%;
    height: 300px;
    width: 85%;
  }
  
  .packagetab-text {
    width: 120%;
    font-size: 14px;
    margin-bottom: 0px;
    margin-left: -15%;
  }
  
  .package-footer {
    margin-top: 10px;
  }
  .package-price {
    font-size: 1.2em;
  }
  
  
  .packagetab-details {
    padding: 0;
  }
  
  .packagetab-details li {
    margin-bottom: 5px;
    font-size: 14px;
  }
  
  .packagetab-details li::before {
    width: 1em;
    margin-left: -1em;
  }
  
  .price-tab {
    font-size: 18px;
  }
  
  .donation-container:hover .heart {
    top: 21vh;
  }
  
  .donate-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  
  .donate-popup-content {
    width: 90%;
    max-width: 500px;
  }
  
  .close-button {
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
  }
  
  .donation-form .form-group {
    margin-bottom: 15px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  .contact-form-container {
    width: 50%;
    translate: 50%;
    }
}
@media (min-width: 951px) and (max-width: 1000px){
  .foreground-layer {
    background-size: 40vh;
    top:4vh;
  }
  
  .background-layer {
    height: 115vh;
  }
  
  .logo-container {
    margin-top: -3vh;
    height: 30vh;
  }

  .logobackground-layer {
    width: 110vw;
    height: 55vh;
    top: -5vh;
    background-position-y: 30%;
  }
  
  .logo {
    width: 35vh;
    margin-right: 3vh;
  }
  
  .title-values {
    font-size: 1.5rem;
    top: 20vh;
  }
  .title-content {
    margin-top:15vh;
    width: 90%;
    translate: 5%;
    line-height: 40px;
    font-size: 20px;
  }
  .moreabout {
    margin-top: 10vh;
  }
  .aboutus {
    margin-top:12vh;
  }
  .programmes {
    height: 100px;
  }
  
  .programmes-title {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .programme-title {
    font-size: 1.5rem;
    left: -1vh;
    margin-bottom: 35px;
  }
    
  .programme-title::before {
    top: 55%;
    left: 33%;
    width: 23vh;
    height: 10vh;
  }
  .programme-titletext {
    margin-left: -4vh;
    top: -2vh;
    font-size: 1.2em;
  }
  .programme-item {
    font-size: 1.2rem;
    line-height: 1.2rem;
    width: 85%;
    margin-top: -5vh;
    translate: 12%;
  }
  .programme-content {
    margin-bottom: 5vh;
    margin-top: -7vh;
  }
  .programme-image {
    width: 23vh; 
    margin-bottom: 10px;
    left: 2vh;
  }
  .programme-text {
    margin-bottom: 3vh;
    letter-spacing: 0.04em;
    margin-left: -7vh;
  }
  .donatenow {
    margin-top: 30vh;
  }
  .bridge-image {
    width: 100%;
    margin-top: 10%;
    height:300px;
  }
  
  .second-content {
    padding-top: 20vh;
  }
  
  .worldwide {
    margin-top: 50px;
  }
  .projects-title {
    margin-top: 5%;
    margin-bottom: 5%
  }
  .world-map {
    width: 120%;
    max-width: 700px;
    margin-left: -4vh;
    margin-bottom:1vh;
  }
  
  .project-filter {
    width: 70%;
    translate: 20%;
  }
  .milestone-title {
    margin-top: 2% !important;
  }   
  .progress-bars {
    width: 70%;
    translate: 20%;
    margin-top:3% !important;
  }
  .progress-bar-container {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .packagetab {
    width: 55%;
    translate:40%;
    padding-top:35px !important;
  }
  .packagetabs-container {
    margin-top: 5%;
    gap:5px;
  }
  .packagetab.visible {
    margin-top:-5% !important;
  }
  .packagetab.visible .packagetab-details {
    max-height: 500px;
  }
  
  .packagetab.collapsed {
    height: 50px;
  }
  
  .packagetab-image {
    margin-bottom: 10px;
    margin-left: 0%;
    height: 300px;
    width: 85%;
  }
  
  .packagetab-text {
    width: 120%;
    font-size: 14px;
    margin-bottom: 0px;
    margin-left: -15%;
  }
  
  .package-footer {
    margin-top: 10px;
  }
  .package-price {
    font-size: 1.2em;
  }
  
  
  .packagetab-details {
    padding: 0;
  }
  
  .packagetab-details li {
    margin-bottom: 5px;
    font-size: 14px;
  }
  
  .packagetab-details li::before {
    width: 1em;
    margin-left: -1em;
  }
  
  .price-tab {
    font-size: 18px;
  }
  
  .donation-container:hover .heart {
    top: 21vh;
  }
  
  .donate-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  
  .donate-popup-content {
    width: 90%;
    max-width: 500px;
  }
  
  .close-button {
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
  }
  
  .donation-form .form-group {
    margin-bottom: 15px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  .contact-form-container {
    width: 50%;
    translate: 50%;
    }
}
@media (min-width: 901px) and (max-width: 950px){
  .foreground-layer {
    background-size: 40vh;
    top:4vh;
  }
  
  .background-layer {
    height: 115vh;
  }
  
  .logo-container {
    margin-top: -3vh;
    height: 30vh;
  }

  .logobackground-layer {
    width: 110vw;
    height: 55vh;
    top: -5vh;
    background-position-y: 30%;
  }
  
  .logo {
    width: 35vh;
    margin-right: 3vh;
  }
  
  .title-values {
    font-size: 1.5rem;
    top: 20vh;
  }
  .title-content {
    margin-top:15vh;
    width: 70%;
    translate: 22%;
    line-height: 40px;
    font-size: 20px;
  }
  .moreabout {
    margin-top: 10vh;
  }
  .aboutus {
    margin-top:12vh;
  }
  .programmes {
    height: 100px;
  }
  
  .programmes-title {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .programme-title {
    font-size: 1.5rem;
    left: -1vh;
    margin-bottom: 35px;
  }
    
  .programme-title::before {
    top: 55%;
    left: 33%;
    width: 23vh;
    height: 10vh;
  }
  .programme-titletext {
    margin-left: -4vh;
    top: -2vh;
    font-size: 1.2em;
  }
  .programme-item {
    font-size: 1.2rem;
    line-height: 1.2rem;
    margin-top: -5vh;
  }
  .programme-content {
    margin-bottom: 5vh;
    margin-top: -7vh;
  }
  .programme-image {
    width: 23vh; 
    margin-bottom: 10px;
    left: 2vh;
  }
  .programme-text-up {
    font-family: wttw_text;
    font-weight: 500;
    width: 130% !important;
    translate: -11%;
    }
  .programme-text {
    margin-bottom: 3vh;
    letter-spacing: 0.04em;
    width: 51%;
    translate: 47%;
    margin-top: -2vh;
  }
  
  .donatenow {
    margin-top: 30vh;
  }
  .bridge-image {
    width: 100%;
    margin-top: 10%;
    height:300px;
  }
  
  .second-content {
    padding-top: 20vh;
  }
  
  .worldwide {
    margin-top: 50px;
  }
  .projects-title {
    margin-top: 5%;
    margin-bottom: 5%
  }
  .world-map {
    width: 120%;
    max-width: 700px;
    margin-left: -4vh;
    margin-bottom:0vh;
  }
  
  .project-filter {
    width: 70%;
    translate: 20%;
  }
  .milestone-title {
    margin-top: 2% !important;
  }   
  .progress-bars {
    width: 70%;
    translate: 20%;
    margin-top:0% !important;
  }
  .progress-bar-container {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .packagetab {
    width: 60%;
    translate:33%;
    padding-top:35px !important;
  }
  .packagetabs-container {
    margin-top: 5%;
    gap:5px;
  }
  .packagetab.visible {
    margin-top:-5% !important;
  }
  .packagetab.visible .packagetab-details {
    max-height: 500px;
  }
  
  .packagetab.collapsed {
    height: 50px;
  }
  
  .packagetab-image {
    margin-bottom: 10px;
    margin-left: 0%;
    height: 300px;
    width: 85%;
  }
  
  .packagetab-text {
    width: 120%;
    font-size: 14px;
    margin-bottom: 0px;
    margin-left: -15%;
  }
  
  .package-footer {
    margin-top: 10px;
  }
  .package-price {
    font-size: 1.2em;
  }
  
  
  .packagetab-details {
    padding: 0;
  }
  
  .packagetab-details li {
    margin-bottom: 5px;
    font-size: 14px;
  }
  
  .packagetab-details li::before {
    width: 1em;
    margin-left: -1em;
  }
  
  .price-tab {
    font-size: 18px;
  }
  
  .donation-container:hover .heart {
    top: 21vh;
  }
  
  .donate-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  
  .donate-popup-content {
    width: 90%;
    max-width: 500px;
  }
  
  .close-button {
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
  }
  
  .donation-form .form-group {
    margin-bottom: 15px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  .contact-form-container {
    width: 50%;
    translate: 50%;
    }
}
@media (min-width: 851px) and (max-width: 900px) {
  .foreground-layer {
    background-size: 40vh;
    top:4vh;
  }
  
  .background-layer {
    height: 115vh;
  }
  
  .logo-container {
    margin-top: -3vh;
    height: 30vh;
  }

  .logobackground-layer {
    width: 110vw;
    height: 55vh;
    top: -5vh;
    background-position-y: 30%;
  }
  
  .logo {
    width: 35vh;
    margin-right: 3vh;
  }
  
  .title-values {
    font-size: 1.5rem;
    top: 20vh;
  }
  .title-content {
    margin-top:15vh;
    width: 73%;
    translate: 20%;
    line-height: 40px;
    font-size: 20px;
  }
  .moreabout {
    margin-top: 10vh;
  }
  .aboutus {
    margin-top:14vh;
  }
  .programmes {
    height: 100px;
  }
  
  .programmes-title {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .programme-title {
    font-size: 1.5rem;
    left: -1vh;
    margin-bottom: 35px;
  }
    
  .programme-title::before {
    top: 55%;
    left: 33%;
    width: 23vh;
    height: 10vh;
  }
  .programme-titletext {
    margin-left: -4vh;
    top: -2vh;
    font-size: 1.2em;
  }
  .programme-item {
    font-size: 1.2rem;
    line-height: 1.2rem;
    margin-top: -5vh;
  }
  .programme-content {
    margin-bottom: 5vh;
    margin-top: -7vh;
  }
  .programme-image {
    width: 20vh; 
    margin-bottom: 10px;
    left: 2vh;
  }
  .programme-text-up {
    font-family: wttw_text;
    font-weight: 500;
    width: 140% !important;
    translate: -15%;
    }
  .programme-text {
    margin-bottom: 3vh;
    letter-spacing: 0.04em;
    width: 53%;
    translate: 44%;
    margin-top: 7vh;
  }
  
  .donatenow {
    margin-top: 30vh;
  }
  .bridge-image {
    width: 100%;
    margin-top: 10%;
    height:300px;
  }
  
  .second-content {
    padding-top: 20vh;
  }
  
  .worldwide {
    margin-top: 50px;
  }
  .projects-title {
    margin-top: 5%;
    margin-bottom: 5%
  }
  .world-map {
    width: 120%;
    max-width: 700px;
    margin-left: -4vh;
    margin-bottom:0vh;
  }
  
  .project-filter {
    width: 70%;
    translate: 20%;
  }
  .milestone-title {
    margin-top: 2% !important;
  }   
  .progress-bars {
    width: 70%;
    translate: 20%;
    margin-top:0% !important;
  }
  .progress-bar-container {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .packagetab {
    width: 60%;
    translate:40%;
    padding-top:35px !important;
  }
  .packagetabs-container {
    margin-top: 5%;
    gap:5px;
  }
  .packagetab.visible {
    margin-top:-5% !important;
  }
  .packagetab.visible .packagetab-details {
    max-height: 500px;
  }
  
  .packagetab.collapsed {
    height: 50px;
  }
  
  .packagetab-image {
    margin-bottom: 10px;
    margin-left: 0%;
    height: 300px;
    width: 85%;
  }
  
  .packagetab-text {
    width: 120%;
    font-size: 14px;
    margin-bottom: 0px;
    margin-left: -15%;
  }
  
  .package-footer {
    margin-top: 10px;
  }
  .package-price {
    font-size: 1.2em;
  }
  
  
  .packagetab-details {
    padding: 0;
  }
  
  .packagetab-details li {
    margin-bottom: 5px;
    font-size: 14px;
  }
  
  .packagetab-details li::before {
    width: 1em;
    margin-left: -1em;
  }
  
  .price-tab {
    font-size: 18px;
  }
  
  .donation-container:hover .heart {
    top: 21vh;
  }
  
  .donate-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  
  .donate-popup-content {
    width: 90%;
    max-width: 500px;
  }
  
  .close-button {
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
  }
  
  .donation-form .form-group {
    margin-bottom: 15px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  .contact-form-container {
    width: 50%;
    translate: 50%;
    }
}
@media (min-width: 801px) and (max-width: 850px) {
  .foreground-layer {
    background-size: 40vh;
    top:4vh;
  }
  
  .background-layer {
    height: 115vh;
  }
  
  .logo-container {
    margin-top: -3vh;
    height: 30vh;
  }

  .logobackground-layer {
    width: 110vw;
    height: 55vh;
    top: -5vh;
    background-position-y: 30%;
  }
  
  .logo {
    width: 35vh;
    margin-right: 3vh;
  }
  
  .title-values {
    font-size: 1.5rem;
    top: 20vh;
  }
  .title-content {
    margin-top:15vh;
    width: 73%;
    translate: 20%;
    line-height: 40px;
    font-size: 20px;
  }
  .moreabout {
    margin-top: 10vh;
  }
  .aboutus {
    margin-top:14vh;
  }
  .programmes {
    height: 100px;
  }
  
  .programmes-title {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .programme-title {
    font-size: 1.5rem;
    left: -1vh;
    margin-bottom: 35px;
  }
    
  .programme-title::before {
    top: 55%;
    left: 33%;
    width: 23vh;
    height: 10vh;
  }
  .programme-titletext {
    margin-left: -4vh;
    top: -2vh;
    font-size: 1.2em;
  }
  .programme-item {
    font-size: 1.2rem;
    line-height: 1.2rem;
    margin-top: -5vh;
  }
  .programme-content {
    margin-bottom: 5vh;
    margin-top: -7vh;
  }
  .programme-image {
    width: 20vh; 
    margin-bottom: 10px;
    left: 2vh;
  }
  .programme-text-up {
    font-family: wttw_text;
    font-weight: 500;
    width: 141% !important;
    translate: -15%;
    }
  .programme-text {
    margin-bottom: 3vh;
    letter-spacing: 0.04em;
    width: 57%;
    translate: 40%;
    margin-top: 7vh;
  }
  
  .donatenow {
    margin-top: 30vh;
  }
  .bridge-image {
    width: 100%;
    margin-top: 10%;
    height:300px;
  }
  
  .second-content {
    padding-top: 20vh;
  }
  
  .worldwide {
    margin-top: 50px;
  }
  .projects-title {
    margin-top: 5%;
    margin-bottom: 5%
  }
  .world-map {
    width: 120%;
    max-width: 700px;
    margin-left: -4vh;
    margin-bottom:0vh;
  }
  
  .project-filter {
    width: 70%;
    translate: 20%;
  }
  .milestone-title {
    margin-top: 2% !important;
  }   
  .progress-bars {
    width: 70%;
    translate: 20%;
    margin-top:0% !important;
  }
  .progress-bar-container {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .packagetab {
    width: 65%;
    translate:25%;
    padding-top:35px !important;
  }
  .packagetabs-container {
    margin-top: 5%;
    gap:5px;
  }
  .packagetab.visible {
    margin-top:-5% !important;
  }
  .packagetab.visible .packagetab-details {
    max-height: 500px;
  }
  
  .packagetab.collapsed {
    height: 50px;
  }
  
  .packagetab-image {
    margin-bottom: 10px;
    margin-left: 0%;
    height: 300px;
    width: 85%;
  }
  
  .packagetab-text {
    width: 120%;
    font-size: 14px;
    margin-bottom: 0px;
    margin-left: -15%;
  }
  
  .package-footer {
    margin-top: -15px;
  }
  .package-price {
    font-size: 1.2em;
  }
  
  
  .packagetab-details {
    padding: 0;
  }
  
  .packagetab-details li {
    margin-bottom: 5px;
    font-size: 14px;
  }
  
  .packagetab-details li::before {
    width: 1em;
    margin-left: -1em;
  }
  
  .price-tab {
    font-size: 18px;
  }
  
  .donation-container:hover .heart {
    top: 21vh;
  }
  
  .donate-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  
  .donate-popup-content {
    width: 90%;
    max-width: 500px;
  }
  
  .close-button {
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
  }
  
  .donation-form .form-group {
    margin-bottom: 15px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  .contact-form-container {
    width: 60%;
    translate: 30%;
    }
}
@media (min-width: 751px) and (max-width: 800px)  {
  .foreground-layer {
    background-size: 40vh;
    top:4vh;
  }
  
  .background-layer {
    height: 115vh;
  }
  
  .logo-container {
    margin-top: -3vh;
    height: 30vh;
  }

  .logobackground-layer {
    width: 110vw;
    height: 55vh;
    top: -5vh;
    background-position-y: 30%;
  }
  
  .logo {
    width: 35vh;
    margin-right: 3vh;
  }
  
  .title-values {
    font-size: 1.5rem;
    top: 20vh;
  }
  .title-content {
    margin-top:15vh;
    width: 80%;
    translate: 12%;
    line-height: 40px;
    font-size: 20px;
  }
  .moreabout {
    margin-top: 10vh;
  }
  .aboutus {
    margin-top:14vh;
  }
  .programmes {
    height: 100px;
  }
  
  .programmes-title {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .programme-title {
    font-size: 1.5rem;
    left: -1vh;
    margin-bottom: 35px;
  }
    
  .programme-title::before {
    top: 55%;
    left: 33%;
    width: 23vh;
    height: 10vh;
  }
  .programme-titletext {
    margin-left: -4vh;
    top: -2vh;
    font-size: 1.2em;
  }
  .programme-item {
    font-size: 1.2rem;
    line-height: 1.2rem;
    margin-top: -5vh;
  }
  .programme-content {
    margin-bottom: 5vh;
    margin-top: -7vh;
  }
  .programme-image {
    width: 20vh; 
    margin-bottom: 10px;
    left: 2vh;
  }
  .programme-text-up {
    font-family: wttw_text;
    font-weight: 500;
    width: 141% !important;
    translate: -14%;
    }
  .programme-text {
    margin-bottom: 3vh;
    letter-spacing: 0.04em;
    width: 60%;
    translate: 35%;
    margin-top: 7vh;
  }
  
  .donatenow {
    margin-top: 30vh;
  }
  .bridge-image {
    width: 100%;
    margin-top: 15%;
    height:250px;
  }
  
  .second-content {
    padding-top: 20vh;
  }
  
  .worldwide {
    margin-top: 50px;
  }
  .projects-title {
    margin-top: 5%;
    margin-bottom: 5%
  }
  .world-map {
    width: 120%;
    max-width: 700px;
    margin-left: -4vh;
    margin-bottom:0vh;
  }
  
  .project-filter {
    width: 70%;
    translate: 20%;
  }
  .milestone-title {
    margin-top: 2% !important;
  }   
  .progress-bars {
    width: 70%;
    translate: 20%;
    margin-top:0% !important;
  }
  .progress-bar-container {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .packagetab {
    width: 75%;
    translate:15%;
    padding-top:35px !important;
  }
  .packagetabs-container {
    margin-top: 5%;
    gap:5px;
  }
  .packagetab.visible {
    margin-top:-5% !important;
  }
  .packagetab.visible .packagetab-details {
    max-height: 500px;
  }
  
  .packagetab.collapsed {
    height: 50px;
  }
  
  .packagetab-image {
    margin-bottom: 10px;
    margin-left: 0%;
    height: 300px;
    width: 85%;
  }
  
  .packagetab-text {
    width: 120%;
    font-size: 14px;
    margin-bottom: 0px;
    margin-left: -15%;
  }
  
  .package-footer {
    margin-top: -15px;
  }
  .package-price {
    font-size: 1.2em;
  }
  
  
  .packagetab-details {
    padding: 0;
  }
  
  .packagetab-details li {
    margin-bottom: 5px;
    font-size: 14px;
  }
  
  .packagetab-details li::before {
    width: 1em;
    margin-left: -1em;
  }
  
  .price-tab {
    font-size: 18px;
  }
  
  .donation-container:hover .heart {
    top: 21vh;
  }
  
  .donate-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  
  .donate-popup-content {
    width: 90%;
    max-width: 500px;
  }
  
  .close-button {
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
  }
  
  .donation-form .form-group {
    margin-bottom: 15px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  .contact-form-container {
    width: 60%;
    translate: 30%;
    }
}
@media (min-width: 701px) and (max-width: 750px)  {
  .foreground-layer {
    background-size: 40vh;
    top:4vh;
  }
  
  .background-layer {
    height: 115vh;
  }
  
  .logo-container {
    margin-top: -3vh;
    height: 30vh;
  }

  .logobackground-layer {
    width: 110vw;
    height: 45vh;
    top: -5vh;
    background-position-y: 30%;
  }
  
  .logo {
    width: 35vh;
    margin-right: 3vh;
  }
  
  .title-values {
    font-size: 1.5rem;
    top: 20vh;
  }
  .title-content {
    margin-top:15vh;
    width: 86%;
    translate: 7%;
    line-height: 40px;
    font-size: 20px;
  }
  .moreabout {
    margin-top: 10vh;
  }
  .aboutus {
    margin-top:5vh;
  }
  .programmes {
    height: 100px;
  }
  
  .programmes-title {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .programme-title {
    font-size: 1.5rem;
    left: -1vh;
    margin-bottom: 35px;
  }
    
  .programme-title::before {
    top: 55%;
    left: 33%;
    width: 23vh;
    height: 10vh;
  }
  .programme-titletext {
    margin-left: -4vh;
    top: -2vh;
    font-size: 1.2em;
  }
  .programme-item {
    font-size: 1.2rem;
    line-height: 1.2rem;
    margin-top: -5vh;
  }
  .programme-content {
    margin-bottom: 5vh;
    margin-top: -7vh;
  }
  .programme-image {
    width: 20vh; 
    margin-bottom: 10px;
    left: 2vh;
  }
  .programme-text-up {
    font-family: wttw_text;
    font-weight: 500;
    width: 141% !important;
    translate: -14%;
    }
  .programme-text {
    margin-bottom: 3vh;
    letter-spacing: 0.04em;
    width: 66%;
    translate: 26%;
    margin-top: 7vh;
  }
  
  .donatenow {
    margin-top: 30vh;
  }
  .bridge-image {
    width: 100%;
    margin-top: 15%;
    height:250px;
  }
  
  .second-content {
    padding-top: 20vh;
  }
  
  .worldwide {
    margin-top: 50px;
  }
  .projects-title {
    margin-top: 5%;
    margin-bottom: 5%
  }
  .world-map {
    width: 90%;
    max-width: 700px;
    margin-left: -4vh;
    margin-bottom:0vh;
  }
  
  .project-filter {
    width: 70%;
    translate: 20%;
  }
  .milestone-title {
    margin-top: 2% !important;
  }   
  .progress-bars {
    width: 70%;
    translate: 20%;
    margin-top:0% !important;
  }
  .progress-bar-container {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .packagetab {
    width: 75%;
    translate:15%;
    padding-top:35px !important;
  }
  .packagetabs-container {
    margin-top: 5%;
    gap:5px;
  }
  .packagetab.visible {
    margin-top:-6% !important;
  }
  .packagetab.visible .packagetab-details {
    max-height: 500px;
  }
  
  .packagetab.collapsed {
    height: 50px;
  }
  
  .packagetab-image {
    margin-bottom: 10px;
    margin-left: 0%;
    height: 300px;
    width: 85%;
  }
  
  .packagetab-text {
    width: 120%;
    font-size: 14px;
    margin-bottom: 0px;
    margin-left: -15%;
  }
  
  .package-footer {
    margin-top: -15px;
  }
  .package-price {
    font-size: 1.2em;
  }
  
  
  .packagetab-details {
    padding: 0;
  }
  
  .packagetab-details li {
    margin-bottom: 5px;
    font-size: 14px;
  }
  
  .packagetab-details li::before {
    width: 1em;
    margin-left: -1em;
  }
  
  .price-tab {
    font-size: 18px;
  }
  
  .donation-container:hover .heart {
    top: 21vh;
  }
  
  .donate-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  
  .donate-popup-content {
    width: 90%;
    max-width: 500px;
  }
  
  .close-button {
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
  }
  
  .donation-form .form-group {
    margin-bottom: 15px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  .contact-form-container {
    width: 60%;
    translate: 30%;
    }
}
@media (min-width: 651px) and (max-width: 700px)  {
  .foreground-layer {
    background-size: 40vh;
    top:4vh;
  }
  
  .background-layer {
    height: 115vh;
  }
  
  .logo-container {
    margin-top: -3vh;
    height: 30vh;
  }

  .logobackground-layer {
    width: 110vw;
    height: 45vh;
    top: -5vh;
    background-position-y: 30%;
  }
  
  .logo {
    width: 35vh;
    margin-right: 3vh;
  }
  
  .title-values {
    font-size: 1.5rem;
    top: 20vh;
  }
  .title-content {
    margin-top: 10vh;
    width: 86%;
    translate: 7%;
    line-height: 40px;
    font-size: 18px;
  }
  .moreabout {
    margin-top: 10vh;
  }
  .aboutus {
    margin-top:5vh;
  }
  .programmes {
    height: 100px;
  }
  
  .programmes-title {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .programme-title {
    font-size: 1.5rem;
    left: -1vh;
    margin-bottom: 35px;
  }
    
  .programme-title::before {
    top: 55%;
    left: 33%;
    width: 23vh;
    height: 10vh;
  }
  .programme-titletext {
    margin-left: -4vh;
    top: -2vh;
    font-size: 1.2em;
  }
  .programme-item {
    font-size: 1.1rem;
    line-height: 1.2rem;
    margin-top: -5vh;
  }
  .programme-content {
    margin-bottom: 5vh;
    margin-top: -7vh;
  }
  .programme-image {
    width: 20vh; 
    margin-bottom: 10px;
    left: 2vh;
  }
  .programme-text-up {
    font-family: wttw_text;
    font-weight: 500;
    width: 141% !important;
    translate: -14%;
    }
  .programme-text {
    margin-bottom: 3vh;
    letter-spacing: 0.04em;
    width: 66%;
    translate: 26%;
    margin-top: 7vh;
  }
  
  .donatenow {
    margin-top: 30vh;
  }
  .bridge-image {
    width: 100%;
    margin-top: 15%;
    height:250px;
  }
  
  .second-content {
    padding-top: 20vh;
  }
  
  .worldwide {
    margin-top: 50px;
  }
  .projects-title {
    margin-top: 5%;
    margin-bottom: 5%
  }
  .world-map {
    width: 90%;
    max-width: 700px;
    margin-left: -4vh;
    margin-bottom:0vh;
  }
  
  .project-filter {
    width: 70%;
    translate: 20%;
  }
  .milestone-title {
    margin-top: 2% !important;
  }   
  .progress-bars {
    width: 80%;
    translate: 10%;
    margin-top:0% !important;
  }
  .progress-bar-container {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .packagetab {
    width: 75%;
    translate:15%;
    padding-top:35px !important;
  }
  .packagetabs-container {
    margin-top: 5%;
    gap:5px;
  }
  .packagetab.visible {
    margin-top:-6% !important;
  }
  .packagetab.visible .packagetab-details {
    max-height: 500px;
  }
  
  .packagetab.collapsed {
    height: 50px;
  }
  
  .packagetab-image {
    margin-bottom: 10px;
    margin-left: 0%;
    height: 250px;
    width: 85%;
  }
  
  .packagetab-text {
    width: 120%;
    font-size: 14px;
    margin-bottom: 0px;
    margin-left: -15%;
  }
  
  .package-footer {
    margin-top: -15px;
  }
  .package-price {
    font-size: 1.2em;
  }
  
  
  .packagetab-details {
    padding: 0;
  }
  
  .packagetab-details li {
    margin-bottom: 5px;
    font-size: 14px;
  }
  
  .packagetab-details li::before {
    width: 1em;
    margin-left: -1em;
  }
  
  .price-tab {
    font-size: 18px;
  }
  
  .donation-container:hover .heart {
    top: 21vh;
  }
  
  .donate-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  
  .donate-popup-content {
    width: 90%;
    max-width: 500px;
  }
  
  .close-button {
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
  }
  
  .donation-form .form-group {
    margin-bottom: 15px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  .contact-form-container {
    width: 60%;
    translate: 30%;
    }
}
@media (min-width: 601px) and (max-width: 650px)  {
  .foreground-layer {
    background-size: 40vh;
    top:4vh;
  }
  
  .background-layer {
    height: 115vh;
  }
  
  .logo-container {
    margin-top: -3vh;
    height: 30vh;
  }

  .logobackground-layer {
    width: 110vw;
    height: 45vh;
    top: -5vh;
    background-position-y: 30%;
  }
  
  .logo {
    width: 35vh;
    margin-right: 3vh;
  }
  .title-content {
    margin-top: 10vh;
    width: 92%;
    translate: 4%;
    line-height: 40px;
    font-size: 18px;
  }
  .moreabout {
    margin-top: 10vh;
  }
  .aboutus {
    margin-top:10vh;
  }
  .title-values {
    font-size: 1.5rem;
    top: 14vh;
  }
  .programmes {
    height: 100px;
  }
  
  .programmes-title {
    font-size: 1.1rem;
    margin-bottom: 20px;
  }
  
  .programme-title {
    font-size: 1.5rem;
    left: -1vh;
    margin-bottom: 35px;
  }
    
  .programme-title::before {
    top: 55%;
    left: 33%;
    width: 23vh;
    height: 10vh;
  }
  .programme-titletext {
    margin-left: -4vh;
    top: -2vh;
    font-size: 1.2em;
  }
  .programme-item {
    font-size: 1.1rem;
    line-height: 1.2rem;
    margin-top: -5vh;
  }
  .programme-content {
    margin-bottom: 12vh;
    margin-top: -11vh;
  }
  .programme-image {
    width: 18vh; 
    margin-bottom: 10px;
    left: 2vh;
  }
  .programme-text-up {
    font-family: wttw_text;
    font-weight: 500;
    width: 141% !important;
    translate: -15%;
    }
  .programme-text {
    margin-bottom: 3vh;
    letter-spacing: 0.04em;
    width: 70%;
    translate: 20%;
    margin-top: -2vh;
  }
  
  .donatenow {
    margin-top: 25vh;
  }
  .bridge-image {
    width: 100%;
    margin-top: 15%;
    height:250px;
  }
  
  .second-content {
    padding-top: 20vh;
  }
  
  .worldwide {
    margin-top: 50px;
  }
  .projects-title {
    margin-top: 5%;
    margin-bottom: 5%
  }
  .world-map {
    width: 90%;
    max-width: 700px;
    margin-left: -4vh;
    margin-bottom:0vh;
  }
  
  .project-filter {
    width: 70%;
    translate: 20%;
  }
  .milestone-title {
    margin-top: 2% !important;
  }   
  .progress-bars {
    width: 80%;
    translate: 10%;
    margin-top:0% !important;
  }
  .progress-bar-container {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .packagetab {
    width: 75%;
    translate:15%;
    padding-top:35px !important;
  }
  .packagetabs-container {
    margin-top: 5%;
    gap:5px;
  }
  .packagetab.visible {
    margin-top:-6% !important;
  }
  .packagetab.visible .packagetab-details {
    max-height: 500px;
  }
  
  .packagetab.collapsed {
    height: 50px;
  }
  
  .packagetab-image {
    margin-bottom: 10px;
    margin-left: 0%;
    height: 250px;
    width: 85%;
  }
  
  .packagetab-text {
    width: 120%;
    font-size: 14px;
    margin-bottom: 0px;
    margin-left: -15%;
  }
  
  .package-footer {
    margin-top: -15px;
  }
  .package-price {
    font-size: 1.2em;
  }
  
  
  .packagetab-details {
    padding: 0;
  }
  
  .packagetab-details li {
    margin-bottom: 5px;
    font-size: 14px;
  }
  
  .packagetab-details li::before {
    width: 1em;
    margin-left: -1em;
  }
  
  .price-tab {
    font-size: 18px;
  }
  
  .donation-container:hover .heart {
    top: 21vh;
  }
  
  .donate-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  
  .donate-popup-content {
    width: 90%;
    max-width: 500px;
  }
  
  .close-button {
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
  }
  
  .donation-form .form-group {
    margin-bottom: 15px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  .contact-form-container {
    width: 70%;
    translate: 20%;
    }
}
@media (min-width: 551px) and (max-width: 600px)  {
  .foreground-layer {
    background-size: 40vh;
    top:4vh;
  }
  
  .background-layer {
    height: 115vh;
  }
  
  .logo-container {
    margin-top: -3vh;
    height: 30vh;
  }

  .logobackground-layer {
    width: 110vw;
    height: 45vh;
    top: -5vh;
    background-position-y: 30%;
  }
  
  .logo {
    width: 35vh;
    margin-right: 3vh;
  }
  .title-content {
    margin-top: 5vh;
    width: 100%;
    translate: -1%;
    line-height: 40px;
    font-size: 16px;
  }
  .moreabout {
    margin-top: 5vh;
  }
  .aboutus {
    margin-top:12vh;
  }
  .title-values {
    font-size: 1.5rem;
    top: 11vh;
  }
  .programmes {
    height: 100px;
  }
  
  .programmes-title {
    font-size: 1.1rem;
    margin-bottom: 20px;
  }
  
  .programme-title {
    font-size: 1.2rem;
    left: -1vh;
    margin-bottom: 35px;
    width:50%;
  }
    
  .programme-title::before {
    top: 55%;
    left: 33%;
    width: 23vh;
    height: 10vh;
  }
  .programme-titletext {
    margin-left: -4vh;
    top: -2vh;
    font-size: 1.2em;
  }
  .programme-item {
    font-size: 1.0rem;
    line-height: 1.2rem;
    margin-top: -5vh;
  }
  .programme-content {
    margin-bottom: 10vh;
    margin-top: -11vh;
  }
  .programme-image {
    width: 16vh; 
    margin-bottom: 10px;
    left: 5vh;
  }
  .programme-text-up {
    font-family: wttw_text;
    font-weight: 500;
    width: 102% !important;
    translate: -1%;
    }
  .programme-text {
    margin-bottom: 3vh;
    letter-spacing: 0.04em;
    width: 90%;
    translate: 5%;
    margin-top: -2vh;
  }
  
  .donatenow {
    margin-top: 25vh;
  }
  .bridge-image {
    width: 100%;
    margin-top: 20%;
    height:200px;
    margin-bottom: 15%;
  }
  
  .second-content {
    padding-top: 20vh;
  }
  
  .worldwide {
    margin-top: 50px;
  }
  .projects-title {
    margin-top: 5%;
    margin-bottom: 5%
  }
  .world-map {
    width: 120%;
    max-width: 700px;
    margin-left: -4vh;
    margin-bottom:0vh;
  }
  
  .project-filter {
    width: 90%;
    translate: 5%;
  }
  .milestone-title {
    margin-top: 2% !important;
  }   
  .progress-bars {
    width: 90%;
    translate: 5%;
    margin-top:0% !important;
  }
  .progress-bar-container {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .packagetabs-container {
    margin-top: 5%;
    gap:5px;
  }

  .packagetab.visible .packagetab-details {
    max-height: 500px;
  }
  
  .packagetab.collapsed {
    height: 50px;
  }
  
  .packagetab-image {
    margin-bottom: 10px;
    margin-left: 0%;
    height: 200px;
    width: 85%;
  }
  
  .packagetab-text {
    width: 120%;
    font-size: 14px;
    margin-bottom: 0px;
    margin-left: -15%;
  }
  
  .package-footer {
    margin-top: -20px;
  }
  .package-price {
    font-size: 1.2em;
  }
  
  
  .packagetab-details {
    padding: 0;
  }
  
  .packagetab-details li {
    margin-bottom: 5px;
    font-size: 14px;
  }
  
  .packagetab-details li::before {
    width: 1em;
    margin-left: -1em;
  }
  
  .price-tab {
    font-size: 18px;
  }
  
  .donation-container:hover .heart {
    top: 21vh;
  }
  
  .donate-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  
  .donate-popup-content {
    width: 90%;
    max-width: 500px;
  }
  
  .close-button {
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
  }
  
  .donation-form .form-group {
    margin-bottom: 15px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
}
@media (min-width: 501px) and (max-width: 550px)  {
  .foreground-layer {
    background-size: 40vh;
    top:4vh;
  }
  
  .background-layer {
    height: 115vh;
  }
  
  .logo-container {
    margin-top: -3vh;
    height: 30vh;
  }

  .logobackground-layer {
    width: 110vw;
    height: 45vh;
    top: -5vh;
    background-position-y: 30%;
  }
  
  .logo {
    width: 35vh;
    margin-right: 3vh;
  }
  .title-content {
    margin-top: 5vh;
    width: 100%;
    translate: -1%;
    line-height: 40px;
    font-size: 16px;
  }
  .moreabout {
    margin-top: 5vh;
  }
  .aboutus {
    margin-top:12vh;
  }
  .title-values {
    font-size: 1.5rem;
    top: 11vh;
  }
  .programmes {
    height: 100px;
  }
  
  .programmes-title {
    font-size: 1.1rem;
    margin-bottom: 20px;
  }
  
  .programme-title {
    font-size: 1.2rem;
    left: -1vh;
    margin-bottom: 35px;
    width:50%;
  }
    
  .programme-title::before {
    top: 55%;
    left: 33%;
    width: 23vh;
    height: 10vh;
  }
  .programme-titletext {
    margin-left: -4vh;
    top: -2vh;
    font-size: 1.2em;
  }
  .programme-item {
    font-size: 1.0rem;
    line-height: 1.2rem;
    margin-top: -5vh;
  }
  .programme-content {
    margin-bottom: 10vh;
    margin-top: -11vh;
  }
  .programme-image {
    width: 16vh; 
    margin-bottom: 10px;
    left: 4vh;
  }
  .programme-text-up {
    font-family: wttw_text;
    font-weight: 500;
    width: 80% !important;
    translate: 15%;
    }
  .programme-text {
    margin-bottom: 3vh;
    letter-spacing: 0.04em;
    width: 90%;
    translate: 5%;
    margin-top: -2vh;
  }
  
  .donatenow {
    margin-top: 25vh;
  }
  .bridge-image {
    width: 100%;
    margin-top: 20%;
    height:200px;
    margin-bottom: 15%;
  }
  
  .second-content {
    padding-top: 20vh;
  }
  
  .worldwide {
    margin-top: 50px;
  }
  .projects-title {
    margin-top: 5%;
    margin-bottom: 5%
  }
  .world-map {
    width: 120%;
    max-width: 700px;
    margin-left: -4vh;
    margin-bottom:0vh;
  }
  
  .project-filter {
    width: 90%;
    translate: 5%;
  }
  .milestone-title {
    margin-top: 2% !important;
  }   
  .progress-bars {
    width: 90%;
    translate: 5%;
    margin-top:0% !important;
  }
  .progress-bar-container {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .packagetabs-container {
    margin-top: 5%;
    gap:5px;
  }

  .packagetab.visible .packagetab-details {
    max-height: 500px;
  }
  
  .packagetab.collapsed {
    height: 50px;
  }
  
  .packagetab-image {
    margin-bottom: 10px;
    margin-left: 0%;
    height: 200px;
    width: 85%;
  }
  
  .packagetab-text {
    width: 120%;
    font-size: 14px;
    margin-bottom: 0px;
    margin-left: -15%;
  }
  
  .package-footer {
    margin-top: -20px;
  }
  .package-price {
    font-size: 1.2em;
  }
  
  
  .packagetab-details {
    padding: 0;
  }
  
  .packagetab-details li {
    margin-bottom: 5px;
    font-size: 14px;
  }
  
  .packagetab-details li::before {
    width: 1em;
    margin-left: -1em;
  }
  
  .price-tab {
    font-size: 18px;
  }
  
  .donation-container:hover .heart {
    top: 21vh;
  }
  
  .donate-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  
  .donate-popup-content {
    width: 90%;
    max-width: 500px;
  }
  
  .close-button {
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
  }
  
  .donation-form .form-group {
    margin-bottom: 15px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
}
@media (min-width: 431px) and (max-width: 500px)  {
  .foreground-layer {
    background-size: 40vh;
    top:4vh;
  }
  
  .background-layer {
    height: 115vh;
  }
  
  .logo-container {
    margin-top: -3vh;
    height: 30vh;
  }

  .logobackground-layer {
    width: 110vw;
    height: 45vh;
    top: -5vh;
    background-position-y: 30%;
  }
  
  .logo {
    width: 35vh;
    margin-right: 3vh;
  }
  .title-content {
    margin-top: 5vh;
    width: 90%;
    translate: 5%;
    line-height: 40px;
    font-size: 16px;
  }
  .moreabout {
    margin-top: 5vh;
  }
  .aboutus {
    margin-top:12vh;
  }
  .title-values {
    font-size: 1.5rem;
    top: 11vh;
  }
  .programmes {
    height: 100px;
  }
  
  .programmes-title {
    font-size: 1.1rem;
    margin-bottom: 20px;
  }
  
  .programme-title {
    font-size: 1.2rem;
    left: -1vh;
    margin-bottom: 35px;
    width:50%;
  }
    
  .programme-title::before {
    top: 55%;
    left: 33%;
    width: 23vh;
    height: 10vh;
  }
  .programme-titletext {
    margin-left: -4vh;
    top: -2vh;
    font-size: 1.2em;
  }
  .programme-item {
    font-size: 1.0rem;
    line-height: 1.2rem;
    margin-top: -5vh;
  }
  .programme-content {
    margin-bottom: 10vh;
    margin-top: -11vh;
  }
  .programme-image {
    width: 16vh; 
    margin-bottom: 10px;
    left: 2vh;
  }
  .programme-text-up {
    font-family: wttw_text;
    font-weight: 500;
    width: 80% !important;
    translate: 15%;
    }
  .programme-text {
    margin-bottom: 3vh;
    letter-spacing: 0.04em;
    width: 90%;
    translate: 5%;
    margin-top: -2vh;
  }
  
  .donatenow {
    margin-top: 25vh;
  }
  .bridge-image {
    width: 100%;
    margin-top: 20%;
    height:200px;
    margin-bottom: 15%;
  }
  
  .second-content {
    padding-top: 20vh;
  }
  
  .worldwide {
    margin-top: 50px;
  }
  .projects-title {
    margin-top: 5%;
    margin-bottom: 5%
  }
  .world-map {
    width: 120%;
    max-width: 700px;
    margin-left: -4vh;
    margin-bottom:0vh;
  }
  
  .project-filter {
    width: 90%;
    translate: 5%;
  }
  .milestone-title {
    margin-top: 2% !important;
  }   
  .progress-bars {
    width: 90%;
    translate: 5%;
    margin-top:0% !important;
  }
  .progress-bar-container {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .packagetabs-container {
    margin-top: 5%;
    gap:5px;
  }

  .packagetab.visible .packagetab-details {
    max-height: 500px;
  }
  
  .packagetab.collapsed {
    height: 50px;
  }
  
  .packagetab-image {
    margin-bottom: 10px;
    margin-left: 0%;
    height: 200px;
    width: 85%;
  }
  
  .packagetab-text {
    width: 120%;
    font-size: 14px;
    margin-bottom: 0px;
    margin-left: -15%;
  }
  
  .package-footer {
    margin-top: -20px;
  }
  .package-price {
    font-size: 1.2em;
  }
  
  
  .packagetab-details {
    padding: 0;
  }
  
  .packagetab-details li {
    margin-bottom: 5px;
    font-size: 14px;
  }
  
  .packagetab-details li::before {
    width: 1em;
    margin-left: -1em;
  }
  
  .price-tab {
    font-size: 18px;
  }
  
  .donation-container:hover .heart {
    top: 21vh;
  }
  
  .donate-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  
  .donate-popup-content {
    width: 90%;
    max-width: 500px;
  }
  
  .close-button {
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
  }
  
  .donation-form .form-group {
    margin-bottom: 15px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
}
@media (min-width: 381px) and (max-width: 430px) {
  .foreground-layer {
    background-size: 40vh;
    top:4vh;
  }
  
  .background-layer {
    height: 115vh;
  }
  
  .logo-container {
    margin-top: -3vh;
    height: 30vh;
  }

  .logobackground-layer {
    width: 110vw;
    height: 45vh;
    top: -5vh;
    background-position-y: 30%;
  }
  
  .logo {
    width: 35vh;
    margin-right: 3vh;
  }
  .title-content {
    margin-top: 5vh;
    width: 100%;
    translate: -1%;
    line-height: 40px;
    font-size: 16px;
  }
  .moreabout {
    margin-top: 5vh;
  }
  .aboutus {
    margin-top:12vh;
  }
  .title-values {
    font-size: 1.5rem;
    top: 11vh;
  }
  .programmes {
    height: 100px;
  }
  
  .programmes-title {
    font-size: 1.1rem;
    margin-bottom: 20px;
  }
  
  .programme-title {
    font-size: 1.2rem;
    left: -1vh;
    margin-bottom: 35px;
    width:50%;
  }
    
  .programme-title::before {
    top: 55%;
    left: 33%;
    width: 23vh;
    height: 10vh;
  }
  .programme-titletext {
    margin-left: -4vh;
    top: -2vh;
    font-size: 1.2em;
  }
  .programme-item {
    font-size: 1.0rem;
    line-height: 1.2rem;
    margin-top: -5vh;
  }
  .programme-content {
    margin-bottom: 10vh;
    margin-top: -11vh;
  }
  .programme-image {
    width: 16vh; 
    margin-bottom: 10px;
    left: 2vh;
  }
  .programme-text-up {
    font-family: wttw_text;
    font-weight: 500;
    width: 80% !important;
    translate: 15%;
    }
  .programme-text {
    margin-bottom: 3vh;
    letter-spacing: 0.04em;
    width: 90%;
    translate: 5%;
    margin-top: -2vh;
  }
  
  .donatenow {
    margin-top: 25vh;
  }
  .bridge-image {
    width: 100%;
    margin-top: 20%;
    height:200px;
    margin-bottom: 15%;
  }
  
  .second-content {
    padding-top: 20vh;
  }
  
  .worldwide {
    margin-top: 50px;
  }
  .projects-title {
    margin-top: 5%;
    margin-bottom: 5%
  }
  .world-map {
    width: 120%;
    max-width: 700px;
    margin-left: -4vh;
    margin-bottom:0vh;
  }
  
  .project-filter {
    width: 90%;
    translate: 5%;
  }
  .milestone-title {
    margin-top: 2% !important;
  }   
  .progress-bars {
    width: 90%;
    translate: 5%;
    margin-top:0% !important;
  }
  .progress-bar-container {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .packagetabs-container {
    margin-top: 5%;
    gap:5px;
  }

  .packagetab.visible .packagetab-details {
    max-height: 500px;
  }
  
  .packagetab.collapsed {
    height: 50px;
  }
  
  .packagetab-image {
    margin-bottom: 10px;
    margin-left: 0%;
    height: 200px;
    width: 85%;
  }
  
  .packagetab-text {
    width: 120%;
    font-size: 14px;
    margin-bottom: 0px;
    margin-left: -15%;
  }
  
  .package-footer {
    margin-top: -20px;
  }
  .package-price {
    font-size: 1.2em;
  }
  
  
  .packagetab-details {
    padding: 0;
  }
  
  .packagetab-details li {
    margin-bottom: 5px;
    font-size: 14px;
  }
  
  .packagetab-details li::before {
    width: 1em;
    margin-left: -1em;
  }
  
  .price-tab {
    font-size: 18px;
  }
  
  .donation-container:hover .heart {
    top: 21vh;
  }
  
  .donate-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  
  .donate-popup-content {
    width: 90%;
    max-width: 500px;
  }
  
  .close-button {
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
  }
  
  .donation-form .form-group {
    margin-bottom: 15px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
}
@media (max-width: 380px) {
  .foreground-layer {
    background-size: 40vh;
    top:4vh;
  }
  
  .background-layer {
    height: 115vh;
  }
  
  .logo-container {
    margin-top: -3vh;
    height: 30vh;
  }

  .logobackground-layer {
    width: 110vw;
    height: 45vh;
    top: -5vh;
    background-position-y: 30%;
  }
  
  .logo {
    width: 35vh;
    margin-right: 3vh;
  }
  .title-content {
    margin-top: 5vh;
    width: 100%;
    translate: -1%;
    line-height: 40px;
    font-size: 16px;
  }
  .moreabout {
    margin-top: 5vh;
  }
  .aboutus {
    margin-top:12vh;
  }
  .title-values {
    font-size: 1.5rem;
    top: 11vh;
  }
  .programmes {
    height: 100px;
  }
  
  .programmes-title {
    font-size: 1.1rem;
    margin-bottom: 20px;
  }
  
  .programme-title {
    font-size: 1.2rem;
    left: -1vh;
    margin-bottom: 35px;
    width:50%;
  }
    
  .programme-title::before {
    top: 55%;
    left: 33%;
    width: 23vh;
    height: 10vh;
  }
  .programme-titletext {
    margin-left: -4vh;
    top: -2vh;
    font-size: 1.2em;
  }
  .programme-item {
    font-size: 1.0rem;
    line-height: 1.2rem;
    margin-top: -5vh;
  }
  .programme-content {
    margin-bottom: 10vh;
    margin-top: -11vh;
  }
  .programme-image {
    width: 16vh; 
    margin-bottom: 10px;
    left: 2vh;
  }
  .programme-text-up {
    font-family: wttw_text;
    font-weight: 500;
    width: 80% !important;
    translate: 15%;
    }
  .programme-text {
    margin-bottom: 3vh;
    letter-spacing: 0.04em;
    width: 90%;
    translate: 5%;
    margin-top: -2vh;
  }
  
  .donatenow {
    margin-top: 25vh;
  }
  .bridge-image {
    width: 100%;
    margin-top: 20%;
    height:150px;
    margin-bottom: 15%;
  }
  
  .second-content {
    padding-top: 20vh;
  }
  
  .worldwide {
    margin-top: 50px;
  }
  .projects-title {
    margin-top: 5%;
    margin-bottom: 5%
  }
  .world-map {
    width: 120%;
    max-width: 700px;
    margin-left: -4vh;
    margin-bottom:0vh;
  }
  
  .project-filter {
    width: 90%;
    translate: 5%;
  }
  .milestone-title {
    margin-top: 2% !important;
  }   
  .progress-bars {
    width: 90%;
    translate: 5%;
    margin-top:0% !important;
  }
  .progress-bar-container {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .packagetabs-container {
    margin-top: 5%;
    gap:5px;
  }

  .packagetab.visible .packagetab-details {
    max-height: 500px;
  }
  
  .packagetab.collapsed {
    height: 50px;
  }
  
  .packagetab-image {
    margin-bottom: 10px;
    margin-left: 0%;
    height: 200px;
    width: 85%;
  }
  
  .packagetab-text {
    width: 120%;
    font-size: 14px;
    margin-bottom: 0px;
    margin-left: -15%;
  }
  
  .package-footer {
    margin-top: -20px;
  }
  .package-price {
    font-size: 1.2em;
  }
  
  
  .packagetab-details {
    padding: 0;
  }
  
  .packagetab-details li {
    margin-bottom: 5px;
    font-size: 14px;
  }
  
  .packagetab-details li::before {
    width: 1em;
    margin-left: -1em;
  }
  
  .price-tab {
    font-size: 18px;
  }
  
  .donation-container:hover .heart {
    top: 21vh;
  }
  
  .donate-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
  
  .donate-popup-content {
    width: 90%;
    max-width: 500px;
  }
  
  .close-button {
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
  }
  
  .donation-form .form-group {
    margin-bottom: 15px;
  }
  
  .donation-form label {
    margin-bottom: 5px;
  }
}
/* @media (min-width: 381px) and (max-width: 430px) {

  .foreground-layer {
    background-size: 40vh;
    top:4vh;
  }

  .background-layer {
    height: 115vh;
  }

  .logo-container {
    margin-top: -7vh;
    height: 30vh;
  }
  .logobackground-layer {
    width: 108vw;
    height: 45vh;
    top: -5vh;
    background-position-y: -20%;
  }
  .logo {
    width: 35vh;
    margin-right: 3vh;
  }
  
  .title-content {
    width: 100%;
    translate: 0%;
    line-height: 40px;
    font-size: 14px;
    margin-top: 15%;
  }
  .moreabout {
    margin-top: 8vh;
  }
  .title-values {
    font-size: 1.5rem;
    position: relative;
    top: 20vh;
  }
  .aboutus {
    margin-top:0vh;
  }
  .programmes {
    height: 100px;
  }
  
  .programmes-title {
    font-size: 1.2rem;
  }
  
  .programme-title {
    font-size: 1.5rem;
    left: -1vh;
    margin-bottom: 25px;
    width: 50%;
  }
    
  .programme-title::before {
    top: 60%;
    left: 35%;
    width: 23vh;
    height: 10vh;
  }
  .programme-titletext {
    margin-left:-4vh;
    top:-1vh;
    font-size: 1.2em;
  }
  .programme-item {
    font-size: 1.0rem;
    line-height: 1.2rem;
    width: 105%;
    margin-top: -5vh;
  }
  .programme-content {
    margin-bottom: 10vh;
  }
  .programme-image {
    width: 15vh; 
    left: 2vh;
  }
  .programme-text {
    letter-spacing: 0.04em;
    width:70%;
    translate: 20%;
  }
  .programme-text-up{
    margin-bottom: 10%;
  }
  .donatenow {
    margin-top: 35vh;
  }
  .bridge-image {
    width: 100%;
    margin-top: 15%;
    height:200px;
  }

  .second-content {
    padding-top: 20vh;
  }

  .worldwide {
    margin-top: 50px;
  }
  .projects-title {
    margin-top: 10%;
    margin-bottom: 10%
  }
  .world-map {
    width: 120%;
    max-width: 700px;
    margin-left: -4vh;
    margin-bottom:1vh;
  }

  .project-filter {
  width: 90%;
  left: 5%;
  }
  .milestone-title {
    margin-top: 2% !important;
  }   
  .progress-bars {
    width: 90%;
    translate: 5%;
    }
  .progress-bar-container {
    margin-bottom: 10px;
    font-size: 16px;
  }

.packagetabs-container {
  margin-top: 5%;
  gap:5px;
}
.packagetab.visible {
margin-top: -12% !important;
}
.packagetab.visible .packagetab-details {
  max-height: 200px;
}

.packagetab.collapsed {
  height: 50px;
}

.packagetab-image {
  margin-bottom: 10px;
  margin-left: 0%;
  height: 200px;
  width:90%;
}

.packagetab-text {
  width: 120%;
  font-size: 14px;
  margin-bottom: 10px;
  margin-left: -15%;
}

.package-footer {
  margin-top: -10px;
}

.package-price {
  font-size: 1.2em;
}


.packagetab-details {
  padding: 0;
  margin: 10px 0;
}

.packagetab-details li {
  margin-bottom: 5px;
  font-size: 13px;
}

.packagetab-details li::before {
  width: 1em;
  margin-left: -1em;
}

.price-tab {
  font-size: 18px;
}

.donation-container:hover .heart {
  top: 21vh;
}

.donate-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
}

.donation-form label {
  margin-bottom: 5px;
}

.donate-popup-content {
  width: 90%;
  max-width: 500px;
}

.close-button {
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
}

.donation-form .form-group {
  margin-bottom: 15px;
}

.donation-form label {
  margin-bottom: 5px;
}
} */

