.logo {
  width: 30vh;
  position: relative;
  z-index: 99;
}

.fixed {
  position: fixed;
  top: 10px;
  left: 20%;
  z-index: 9;
}
.heading {
  margin-top: 5vh;
}

.section-content {
  margin-top: 5vh;
  transition: opacity 1s ease-in-out;

}
.visible {
  opacity: 100%;
}
.hidden {
  opacity: 0;
}
.section p {
  font-size: 1rem;
}

.team-container {
  transition: opacity 1.5s ease-in-out;
  overflow: hidden;
  height: auto;
  padding: 20px 20px 0px 20px;
}

.transition-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  z-index: 10;
  border-radius: 50%;
  transition: width 1s ease-in-out, height 1s ease-in-out, top 1s ease-in-out, left 1s ease-in-out, border-radius 1s ease-in-out;
}

.expand {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  border-radius: 0;
}

.shrink {
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  border-radius: 50%;
}

.departments-container {
  display: grid;
  align-items: center;
  justify-content: center;
  opacity: 0;
  position: relative;
  grid-template-columns: 17vh 17vh 25vh;
  margin-top: -25vh;
}

.departments-container.fade-in {
  opacity: 1;
  z-index: 99;
}

.department-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  opacity: 0;
  animation: fadeIn 1.5s ease-in-out forwards;
  transition: color 0.3s ease-in-out;
  color: #000;
  width: 25vh;
  height: 13vh;
}

.department-button p {
  margin-top: 10px;
  transition: color 0.3s ease-in-out;
}

.department-button:hover p {
  color: #063f81; /* Change to the desired hover color */
}

.department-button:hover svg {
  color: #063f81; /* Change SVG color on hover */
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.icon {
  font-size: 3em;
}

@media (min-width: 1922px) {
}
@media (min-width: 1851px) and (max-width: 1921px) {
}
@media (min-width: 1770px) and (max-width: 1850px) {
}
@media (min-width: 1701px) and (max-width: 1770px) {
}
@media (min-width: 1601px) and (max-width: 1700px) {
}
@media (min-width: 1501px) and (max-width: 1600px) {
}
@media (min-width: 1451px) and (max-width: 1500px) {
}
@media (min-width: 1401px) and (max-width: 1450px) {
}
@media (min-width: 1351px) and (max-width: 1400px) {
}
@media (min-width: 1301px) and (max-width: 1350px) {
}
@media (min-width: 1251px) and (max-width: 1300px) {
}
@media (min-width: 1201px) and (max-width: 1250px) {
}
@media (min-width: 1151px) and (max-width: 1200px) {
}
@media (min-width: 1101px) and (max-width: 1150px) {
}
@media (min-width: 1051px) and (max-width: 1100px) {
}
@media (min-width: 1001px) and (max-width: 1050px) {
}
@media (min-width: 951px) and (max-width: 1000px){
}
@media (min-width: 901px) and (max-width: 950px){
}
@media (min-width: 851px) and (max-width: 900px) {
}
@media (min-width: 801px) and (max-width: 850px) {
}
@media (min-width: 751px) and (max-width: 800px)  {
}
@media (min-width: 701px) and (max-width: 750px)  {
}
@media (min-width: 651px) and (max-width: 700px)   {
}
@media (min-width: 651px) and (max-width: 700px)   {
}
@media (min-width: 601px) and (max-width: 650px) {
}
@media (min-width: 551px) and (max-width: 600px)  {
}
@media (min-width: 501px) and (max-width: 550px)   {
}
@media (min-width: 431px) and (max-width: 500px)  {
}
@media (min-width: 381px) and (max-width: 430px) {
}
@media (max-width: 380px) {
}